import * as React from 'react';
import { useCallback, useContext, useEffect, useState } from "react"
import { UserContext } from "./context/UserContext"
import Loader from "./Loader"

import Dashboard from "./Dashboard"
import './Auth.css'
import { Button, Tabs, Tab , Card} from "@blueprintjs/core";
import SignUp from "./Signup"
import SignIn from "./SignIn"
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';




function Auth() {
  const [tab, setTab] = useState("login")
  const [open, setOpen] = React.useState(false);
  const [success, setSuccess] = useState(false)
  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  /*
<Alert severity="success">
              <AlertTitle>Success !</AlertTitle>
              Your account has been successfully created! — <strong>Sign in to get started!</strong>
            </Alert>
  */

  return (

    
    <div className="auth-container">
     
      <div className="second-auth-container">
      
            <div className='logintitle-container'>
                 <h1>  <span onClick={() => setTab('login')} className={tab === 'login' ? "login-title" : 'register-loginpage'}>Sign In /</span> <span onClick={() => setTab('register')} className={tab === 'register' ?  "login-title" : 'register-loginpage'}>Sign Up</span></h1>
             </div>
             

             {tab === 'login' ? <SignIn success={success} setTab={setTab}/> : <SignUp setSuccess={setSuccess} setOpen={setOpen} setTab={setTab}/> }
 
      </div>
     
    </div>

  
    
  )
}

export default Auth
