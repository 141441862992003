import { Button, Callout, InputGroup,FormGroup } from "@blueprintjs/core";
import React, { useContext, useState } from "react"
import { UserContext } from "./context/UserContext"
import { Routes, Route, Link } from "react-router-dom";
import { useNavigate } from 'react-router'
import { baseurl } from "./env";
import { GoogleLogout, GoogleLogin } from 'react-google-login'


const Register = () => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [error, setError] = useState("")
  const [firstname, setFirstName] = useState("")
  const [lastname, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [userContext, setUserContext] = useContext(UserContext)
  const navigate = useNavigate()
  const [status, setStatus] = useState("Register")
  const [registerdata, setRegisterdata ] = useState('')

  async function formSubmitHandler(e){
    setStatus("Register ...")
    e.preventDefault()
    setIsSubmitting(true)
    setError("")

    const genericErrorMessage = "Something went wrong! Please try again later."

    await fetch(`${baseurl}/register`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ firstname, lastname, email: email, password }),
    })
    .then(res => res.json())
    .then(data => {
      setRegisterdata(data)
        if (data.message === "Success"){
          setError(data.message)
          navigate('/dashboard')

        } else if (data.message === "Email has already been taken") {
          setError(data.message)
          setStatus("Register")
          
        }
        
    })
     if (registerdata.message === "Success") {
      await navigate('/auth')
     } else {
      await navigate('/auth/register')
     }
     
    
}


const handleLogin = async googleData => {
  const res = await fetch(`${baseurl}/api/v1/auth/google`, {
      method: "POST",
      body: JSON.stringify({
      token: googleData.tokenId
    }),
    headers: {
      "Content-Type": "application/json"
    }
  })
  .then(res => res.json())
  .then(data => {
      console.log(data)
        localStorage.setItem("token", data.token)
        
        data.message === "Success" ? navigate('/dashboard'): setError('Incorrect password') 
        console.log(localStorage)
    })
  
  
  // store returned user somehow
}

  return (
    <>
      

      



      <form onSubmit={formSubmitHandler} className="auth-form">

        


        <div className="form-container">
        {error && <Callout intent="danger">{error}</Callout>}
             <div>
            <input placeholder='First name'  id="firstName" value={firstname} onChange={e => setFirstName(e.target.value)}/>
            </div>
            <div>
            <input placeholder='Last Name'  id="lastname" value={lastname} onChange={e => setLastName(e.target.value)}/>
            </div>
            <div>
            <input placeholder='Email'  id="email" value={email} onChange={e => setEmail(e.target.value)}/>
            </div>
            <div>
            <input placeholder='password' type="password"  id="password" value={password} onChange={e => setPassword(e.target.value)}/>
            </div>
            <button type="submit" className="loginbutton">{status}</button>
            <div className="google-button">
                <div className="google-sub"></div>
                <GoogleLogin
                      theme="dark"
                      clientId={'206368780753-e2qo3s2tle0gj97f3pur615qb5grf7ne.apps.googleusercontent.com'}
                      buttonText="Sign up with Google"
                      onSuccess={handleLogin}
                      onFailure={handleLogin}
                      cookiePolicy={'single_host_origin'}
                  />
            </div>

        </div>
    
      </form>
    </>
  )
}

export default Register
