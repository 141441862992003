import React from 'react'
import './Home.css'
import illustration from './img/dashboard.png'
import { Routes, Route, Link } from "react-router-dom";
import peakficart from './img/peakfi-portfolio-tracker.gif'
import {Helmet} from "react-helmet";
import Cryptoanalysis from './img/crypto-portoflio-analysis.png'
import Iconpeakfi from './img/icon-peakfi.svg'
import Cryptoicon from './img/crypto-tracking.svg'
import Timeicon from './img/time-icon.svg'
import Charticon from './img/icon-chart.svg'
import peakfiportfolio from './img/peakfi-portfolio-tracker.png'
import peakfidashboard from './img/peakfi-dashboard.png'
import peeakfidashboardchart from './img/peeakfi-dashboard-chart.png'
import peakfidividendgrowth from './img/peakfi-dividend-growth.png'
import Footer from './Footer';

function Home() {
    return (
        <div className='home-container'>
              <Helmet>
                <title>PeakFi.co - #1 Investment portfolio tracker</title>
                <meta name="description" content="The fastest and easiest way to get a clear sight of your portfolio investment. Stocks, ETFs, Crypto and more !" />
            </Helmet>
            <div className='subcontainer-home'>
                <div className='title-container-home'>
                    <p className='portfolio-dividend-yield'>500+ Portfolio tracked  🎉</p>
                    <h1 className='header-title'>Become a better Investor</h1>
                    <h3>Track your portofolio and find opportunities.</h3>
                    <p className='metadescription'>PeakFi helps investors to analyze their portofolio and provides key metrics such as dividend yield, compound interests, best performing, repartition and more.</p>
                    <div className='button-container-home'>
                        <button className='createafreeaccount'><Link className="homepage-link"   to="/auth"  >Create a free account</Link></button>
                        <button className='findstocks-button'><Link className="homepage-link-black-button"   to="/stocks"  >Find Stocks</Link></button>
                    </div>
                </div>
                <div className='image-container'>
                    <div className='illustration-container'>
                    <img className='illustration' src={peakfiportfolio}/>
                    </div>
                </div>

            </div>
            <div className='second-subcontainer-home'>
                <div className='greenpart'>
                    <div>
                <h1>Investing shouldn't be boring</h1>
                </div>
                <div className='flex-container'>
                    <div className='green-item'>
                        <div className='icon-container'>
                        <img className='icon-homepage' src={Iconpeakfi}/>
                        </div>
                       
                        <h2>Track your dividends</h2>
                        <p className='metadescription'>Import your trading history, then sit back and watch as corporate actions such as dividends, and stock splits are automatically incorporated.</p>
                        <button className='learnmore-button'>Learn more</button>
                    </div>
                    <div className='green-item'>
                    <div className='icon-container-yellow'>
                        <img className='icon-homepage-yellow' src={Charticon}/>
                        </div>
                        <h2>Know your true performance</h2>
                        <p className='metadescription'>Benchmark your portfolio with indices or with the community to make sure that you are in the right direction.</p>
                        <button className='learnmore-button'>Learn more</button>
                    </div>
                    <div className='green-item'>
                    <div className='icon-container-red'>
                        <img className='icon-homepage-red' src={Timeicon}/>
                        </div>
                        <h2>Compound Interests</h2>
                        <p className='metadescription'>Estimate the potential growth of your portfolio's net value and get an idea of how much money you could make with dividends in 1, 10 or 50 years!</p>
                        <button className='learnmore-button'>Learn more</button>
                    </div>
                    <div className='green-item'>
                    <div className='icon-container-green'>
                        <img className='icon-homepage-green' src={Cryptoicon}/>
                        </div>
                        <h2>Track any type of asset</h2>
                        <p className='metadescription'>Peakfi allows you to track several assets such as comon Stocks, ETFs, indices, Crypto and even real estate.</p>
                        <button className='learnmore-button'>Learn more</button>
                    </div>

                </div>
                </div>
            </div>
            

            <div className='subcontainer-home'>

                <div className='image-container'>
                    <div className='illustration-container'>
                    <img className='chart-gif' src={peakfidashboard}/>
                    </div>
                </div>


                <div className='title-container-home'>
                <p className='portfolio-onyear'>Visual & Easy to use</p>
                    <h1>We've simplified the process of analysis</h1>
                    <h3>Find opportunities at a glance</h3>
                    <p className='metadescription'>PeakFi is easy to use and highly visual. No complicated price asked. Just create your portfolio, add assets .. and that's it !</p>
                    

                </div>


            </div>

           

            
            <div className='subcontainer-home'>

                

                <div className='title-container-home'>
                <p className='dividendprojection-title'>Dividend estimations</p>
                    <h1>Powerful divdend Growth estimation</h1>
                    <h3>Prepare the futur as precisely as possible.</h3>
                    <p className='metadescription'>Add stocks to your portfolio and Peakfi will automatically give you an estimation of your dividend growth for the next 40 years.</p>
                    
                </div>

                <div className='image-container'>
                    <div className='illustration-container'>
                    <img className='chart-gif' src={peakfidividendgrowth}/>
                    </div>
                </div>

                

            </div>

           


            <div className='subcontainer-home'>

                <div className='image-container'>
                    <div className='illustration-container'>
                    <img className='chart-gif' src={peakfiportfolio}/>
                    </div>
                </div>


                <div className='title-container-home'>
                <p className='onyearpositive'>Benchmarks</p>
                    <h1>Benchmark your portfolios performances</h1>
                    <h3>Compare your performances with other investors.</h3>
                    <p className='metadescription'>You want to make sure you have the best portfolio ? Compare your portfolio performances with other users portfolios.</p>
                    
                </div>
                

            </div>
            <Footer/>
        </div>
       
    )
}

export default Home
