import React from 'react'
import './Pricing.css'
import check from './img/check.svg'
import StripeContainer from "./Stripe/StripeContainer";
import { baseurl } from "./env"
import { useEffect, useState } from 'react'
import { useNavigate, Redirect } from 'react-router'



function Pricing() {


    const [url, setUrl] = useState('')
    const navigate = useNavigate()

  async function handleClick  (e) {

    e.preventDefault()
    console.log('data sent')
   const response =  await fetch(`${baseurl}/create-checkout-session`, {
        method:"POST",
        headers: {
             "x-access-token": localStorage.getItem('token')
        }
    })
   
    const data = await response.json()
    setUrl(data.url)
    console.log(url)
    await window.location.replace(data.url)

  }

    
    return (
        <div>
            <div className='pricing-container'>
                <div className='pricing-title'>
                <h1>It's easy to get Started</h1>
                <p>Start your 7 day free trial and start making better investments.</p>
                </div>
            
            </div>

            <div className='fact-container'>
                <div  className='fact-subcontainer'>
                    <div>
                        <p> <img className='title-feature' src={check}/> 7 days free trial</p>
                    </div>
                    <div>
                        <p> <img className='title-feature'  src={check}/> Cancel at any time</p>
                    </div>
                    <div>
                        <p><img className='title-feature'  src={check}/> Unlimited usage</p>
                    </div>


                </div>

            </div>



            <div className='pricetable-container'>
                
                <div className='pricetable-subcontainer'>
                    <div className='price-header'>
                    <h1>⭐ Premium</h1>
                    <p>The perfect option for <span className='price-span'>investors</span> that want to optimize their <span className='price-span'>portfolio</span>.</p>
                    </div>

                    <div className='price-body'>
                    <h1>$19 / mo</h1>
                    <button onClick={handleClick} className='start-for-free'>Start for free</button>
                    </div>

                    <div className='price-features'>
                    <p> <img className='check-feature' src={check}/> Up to 5 portfolios</p>
                    <p> <img className='check-feature' src={check}/> Track over 54k Stocks</p>
                    <p> <img className='check-feature' src={check}/> Track over 3k ETFs</p>
                    <p> <img className='check-feature' src={check}/> Track over 3k Crypto</p>
                    <p> <img className='check-feature' src={check}/>Access compound interests & dividend calendar</p>
                    <p> <img className='check-feature' src={check}/>Premium support</p>

                    </div>
                    
                    

                </div>

                <div className='pricetable-subcontainer'>
                    <div className='price-header'>
                    <h1>🚀 Investor</h1>
                    <p>The perfect option for <span className='price-span'>investors</span> that want to optimize their <span className='price-span'>portfolio</span>.</p>
                    </div>

                    <div className='price-body'>
                    <h1>$29 / mo</h1>
                    <button onClick={handleClick} className='start-for-free'>Start for free</button>
                    </div>

                    <div className='price-features'>
                    <p> <img className='check-feature' src={check}/> Up to 100 portfolios</p>
                    <p> <img className='check-feature' src={check}/> Track over 54k Stocks</p>
                    <p> <img className='check-feature' src={check}/> Track over 3k ETFs</p>
                    <p> <img className='check-feature' src={check}/> Track over 3k Crypto</p>
                    <p> <img className='check-feature' src={check}/>Access compound interests & dividend calendar</p>
                    <p> <img className='check-feature' src={check}/>Access dividend evolution</p>
                    <p> <img className='check-feature' src={check}/>Access community portfolios</p>
                    <p> <img className='check-feature' src={check}/>Premium support</p>

                    </div>
                    
                    

                </div>

            </div>

            
            
        </div>
    )
}

export default Pricing
