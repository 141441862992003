import React, { useCallback, useState, useEffect, useContext } from "react"
import { ThemeProvider, createTheme, styled, useTheme } from '@mui/material/styles';
import { Routes, Route, Link } from "react-router-dom";
import './Overview.css'
import Portfoliocards from "./Portfoliocards";
import { UserContext } from './UserContext'
import { UserTabContext } from './UserTabContext'
import { UserPortfolioContext } from './UserPortfolioContext'
import AddIcon from '@mui/icons-material/Add';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Button from '@mui/material/Button';
import { UserTriggerContext } from './UserTriggerContext';
import { baseurl } from "./env"



function Overview(props) {

    const [settings, setSettings] = useState(false)
    const [numberofstocks, setNumberofstocks] = useState(0)

    const [value, setValue] = useContext(UserContext)
    const [selectedIndex, setSelectedIndex] = useContext(UserTabContext);
    const [activeportfolios, setActiveportfolios] = useContext(UserPortfolioContext);



    const [mopen, setMopen] = useState(false);
    const handleOpen = () => setMopen(true);
    const handleClose = () => setMopen(false);
  
    const [snacksuccess, setSnacksuccess] = React.useState(false);
  
    const handleCloseSnack = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setSnacksuccess(false);
    };
  
  
    const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      bgcolor: '#2a3042',
      color: '#FFFFFF',
      border: '2px solid #000',
      boxShadow: 24,
      p: 4,
    };
  
    const [trigger, setTrigger] = useContext(UserTriggerContext)
    const [creating, setCreating] = useState('Create')
    const [portfolioname, setPortfolioname] = useState(null)
    const [portfoliodescription, setPortfoliodescription]= useState(null)
  
    async function handleSubmit(e){
      e.preventDefault();
      setCreating('Creating...')
      
      setTrigger(!trigger)
  
      
          const user =  {
              portfolio: portfolioname,
              descritpion: portfoliodescription
          }
      
      // eslint-disable-next-line no-console
      const genericErrorMessage = "Something went wrong! Please try again later."
  
      await fetch(`${baseurl}/createportfolio`, {
          method:"POST",
          headers: {
              "content-type": "application/json",
              "x-access-token": localStorage.getItem('token')
          },
          body: JSON.stringify({portfolio: portfolioname, descritpion: portfoliodescription })
      })
      .then(res => res.json())
      .then(data => {
          function SuccessfullCreation () {
              setCreating('Successfully created !')
              setSnacksuccess(true)
              handleClose()
          }
          data.message === "Success" ? SuccessfullCreation () : setCreating('Error during creation')
          
      })
    };

    const darkTheme = createTheme({
        components: {
            MuiDrawer: {
              styleOverrides: {
                paper: {
                  backgroundColor: "#2a3042",
                  color: "#a6b0cf",
                }
              }
            },
            MuiAppBar: {
                styleOverrides: {
                    root: {
                    backgroundColor: "#ffffff",
                    color: "#555b6d",
                    fontWeight:'900'
                  }
                }
              },
              MuiListSubheader: {
                styleOverrides: {
                    root: {
                    backgroundColor: "#2a3042",
                    color: "#ffffff",
                    fontWeight:'900'
                  }
                }
              }
          },
        palette: {
          mode: 'dark',
          primary: {
            main: '#ffffff',
          },
          thirdary: {
            main: '#7B68EE',
          },
        },
      });

    



    function SetTabandActiveP (portfolioindex) {
        setActiveportfolios(portfolioindex)
        setSelectedIndex('dashboard')
    }

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      }
      
  return( 
        <div className="container-overview">
            <div className="dashboard-container">
                <div className="header-photo-welcome-container">
                        <div className="hpw-sub1">
                            <p>Welcome {value.data ? capitalizeFirstLetter(value.data.firstname) : ""} 👋 </p>
                            <p className="numberofstocks">You are currently under the <span className="portfolio-dividend-yield">{value.data ? value.data.plan : null} plan.</span> {value.data && value.data.plan === 'premium' ?  null : 'Upgrade to get key metrics.'}</p>
                            <p className="numberofstocks">You have {value && value.portfolios ? (value.portfolios).length : 0} portfolios.</p>
                        </div>

                        <div className="hpw-sub2">
                          
                        
                        </div>

                        
        
                </div>
            </div>
                <div className="portfoliocards-container">
                    {value && value.portfolios ? (value.portfolios.map ((p, index) => 
                    <div key={p.name} className="portfoliocards-map-container" onClick={()=>SetTabandActiveP(index)}><Portfoliocards name={p.name} stocks={p.stocks} index={index} networth={p.networth} oneyeargrowth={p.oneyeargrowth} yearlydividend={p.yearlydividend} dividendyield={p.dividendyield}/>
                    </div>)) : null}
                </div>

                <div className="portfoliocards-container">
                        <div className="portfoliocards-map-container">
                            <div className='overview-add-button' onClick={handleOpen}>
                                <p  className="overview-add"><AddIcon fontSize="large" />New portfolio</p>
                            </div>
                        </div>
                        
                </div>
                <ThemeProvider theme={darkTheme}>
                <Modal
                        open={mopen}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                        <AddCircleOutlineIcon/> Create a new portfolio
                        </Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            <div className="create-portfolio-subtitle">
                            Let's create a new portfolio
                            </div>
                        </Typography>
                        <Box
                            component="form"
                            sx={{
                                '& > :not(style)': { m: 1, width: '25ch' },
                            }}
                            noValidate
                            autoComplete="off"
                            >
                            <TextField  id="outlined-basic" label="Name" variant="outlined" placeholder="My awesome Portfolio" onChange={e => setPortfolioname(e.target.value)} />
                            
                            <TextField
                                
                                id="filled-multiline-static"
                                label="Description"
                                multiline
                                rows={3}
                                placeholder="My amazing description"
                                variant="filled"
                                onChange={e => setPortfoliodescription(e.target.value)} 
                                />

                            <Button variant="contained" color="thirdary" onClick={handleSubmit}>{creating}</Button>
                            </Box>

                        
                        
                        </Box>

                        
                </Modal>
                </ThemeProvider>
                        
    </div>
        
        
)
}

export default Overview;
