import React, {useState, createContext, useEffect} from 'react';
import { baseurl } from "./env"

export const UserTabContext = createContext();

export const UserTabProvider = (props) => {


   
    const [selectedIndex, setSelectedIndex] = useState(0)


return (
<UserTabContext.Provider  value={[selectedIndex, setSelectedIndex]} >
 {props.children}
</UserTabContext.Provider>
);

}
