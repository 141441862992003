import React from 'react'
import './Community.css'
import Portfoliocards from './Portfoliocards'
import { useState, useEffect } from 'react';
import { baseurl } from "./env"

function Community() {
    const [portfolios, setPortfolios] = useState(null)


    const fetchData = async () => {
        
          await fetch(`${baseurl}/communityportfolios`, {
            method:"GET",
            
          })
            .then((response) => response.json())
            .then((result) => {
               setPortfolios(result.portfolios) 
            })
        }
      
        useEffect(() => {
          fetchData();
           
      }, [])


    return (
        <div>
            <div className='billing-container'>
            <p className='portfolio-dividend-yield'>Community Portfolios</p>
                <h4>Do you need inspiration ? </h4>
                <p>Check out portfolios of the commmunity.</p>

            </div>

            <div className="portfoliocards-container">
                    {portfolios ? (portfolios.map ((p, index) => 
                    <div className="portfoliocards-map-container" ><Portfoliocards name={p.name} stocks={p.stocks} index={index} networth={p.networth} oneyeargrowth={p.oneyeargrowth} yearlydividend={p.yearlydividend} dividendyield={p.dividendyield}/>
                    </div>)) : null}
                </div>
        </div>
    )
}

export default Community