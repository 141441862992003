import React from 'react'
import './Invoices.css'

function Subscriptions() {
    return (
        <div>
            <div className='billing-container'>
                <p className='portfolio-dividend-yield'>Subscriptions</p>
                <h3>You don't have any Subscriptions yet.</h3>

            </div>
        </div>
    )
}

export default Subscriptions