import React from 'react'
import './Faq.css'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function Faq() {
    return (
        <div>
            <div className='billing-container'>
            <p className='portfolio-dividend-yield'>Faq</p>

            <Accordion>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                <Typography>How accurate are Peakfi's predictions ?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography>
                    Our predictions for Dividends and Values evolutions are based on historical growth. It's not a guarantee that the growth rate will remain constant through years.
                </Typography>
                </AccordionDetails>
            </Accordion>


            <Accordion>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                <Typography>How often are the data updated ?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography>
                    Prices of Stocks, ETFs and Crypto are updated on a daily basis. Divdend history are updated on a monthly basis.
                </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>How many Portfolios can I create ?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            You can create as many portfolios as you want.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>How do I prevent my portfolio to be public ?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            You set your portfolio as private by switching your porfolio to private mode.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>How do I cancel my subscription ?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            You can cancel your subscription on the 'Subscription' tab on the left menu.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>How do I upgrade my plan ?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            If you are under the free plan you can go to https://peakfi.co/pricing and pick up the plan you want. If you already have an active premium plan, you can go on the 'Subscription' tab on the left menu.
          </Typography>
        </AccordionDetails>
      </Accordion>
     

            </div>
        </div>
    )
}

export default Faq