import React from "react"
import ContentLoader from "react-content-loader"

const MyLoader = (props) => (
  <ContentLoader 
    speed={2}
    width={50}
    height={150}
    viewBox="0 0 400 150"
    backgroundColor="#f3f3f3"
    foregroundColor="#E9EBF0"
    style={{ width: '100%' }}
    {...props}
  >
    
    <rect x="20" y="45" rx="5" ry="5" width="100px" height="100px" /> 

    <rect x="140" y="45" rx="5" ry="5" width="300" height="10" /> 
    
    <rect x="140" y="75" rx="5" ry="5" width="300" height="10" /> 
    
    <rect x="140" y="105" rx="5" ry="5" width="300" height="10" /> 
   
    <rect x="140" y="135" rx="5" ry="5" width="300" height="10" />
  </ContentLoader>
)

export default MyLoader