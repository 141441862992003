import React, {useState, createContext, useEffect} from 'react';


export const UserTriggerContext = createContext();

export const UserTriggerProvider = (props) => {

    const [trigger, setTrigger] = useState(true)
    
return (
<UserTriggerContext.Provider  value={[trigger, setTrigger]} >
 {props.children}
</UserTriggerContext.Provider>
);

}