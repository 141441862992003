import { useCallback, useContext, useEffect, useState } from "react"
import { UserContext } from "./context/UserContext"
import Loader from "./Loader"
import Register from "./Register"
import Dashboard from "./Dashboard"
import './Auth.css'
import { Button, Tabs, Tab , Card} from "@blueprintjs/core";


function Authregister() {
  const [currentTab, setCurrentTab] = useState("login")
 

 

  return (

    
    <div className="auth-container">
      <div className="second-auth-container">
        
      <Register/>
 
      </div>
     
    </div>

  
    
  )
}

export default Authregister
