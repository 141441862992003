import React, {useState} from 'react';
import './Portfoliocards.css'
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import { Sparklines, SparklinesLine, SparklinesCurve } from 'react-sparklines';
import moment from "moment"
import 'chart.js/auto';
import { Chart } from 'react-chartjs-2';


function Portfoliocards(props) {



  

const networth = (props && props.networth ? props.networth : 0)

    const numberofstocks = props && props.stocks ? props.stocks : 0
    const StyledBadge = styled(Badge)(({ theme }) => ({
        '& .MuiBadge-badge': {
          right: 23,
          top: 13,
          border: `2px solid ${theme.palette.background.paper}`,
          padding: '0 4px',
        },
      }));

      const dataset = [1,3,4,2,8]


      const finalarray = FindStocksbyDate() 
  const merge3 = finalarray.flat(1); //Here is our array of data

  function sortObjectByKeys(o) {
    return Object.keys(o).sort().reduce((r, k) => (r[k] = o[k], r), {}); /// Function to keep the same order on the array
  }

const countss = {};        // Array of data final version
merge3.forEach((x) => {
  countss[x.date] = (countss[x.date] || 0) + x.close;
});

const counts = sortObjectByKeys(countss)


const countsx = (Object.keys(counts))
const countsy = (Object.values(counts))

//console.log(countsx)
//console.log(countsy)


  function FindStocksbyDate () {
    const value = [];
    for (let i = 0; i < numberofstocks.length; i++) { 
      const quantity = numberofstocks[i].quantity 
      
     const returnedvalue = LoopthroughEOD(quantity, i)
     value.push(returnedvalue);
     
  }  return value
 
  }



function LoopthroughEOD(quantity, a) {
  const subsub_array = [];
  const eod = numberofstocks[a] && numberofstocks[a].stockss && numberofstocks[a].stockss.Eodstats ? numberofstocks[a].stockss.Eodstats : 0
  const name = numberofstocks[a] && numberofstocks[a].stockss && numberofstocks[a].stockss.Code ? numberofstocks[a].stockss.Code : null
  const type = numberofstocks[a] && numberofstocks[a].stockss && numberofstocks[a].stockss.Code ? numberofstocks[a].stockss.Type : null
  ////console.log(JSON.stringify(eod))

           
          for (let i = 1; i < eod.length; i++) {  

            var dateObj = new Date(eod[i].date);
            var momentObj = moment(dateObj).format("YYYY MM");

            
            const resultobject = { date: momentObj, name: name, type: type, close: (eod[i].adjusted_close)*quantity};
            subsub_array.push(resultobject);
           
          }
         
          return subsub_array

}


const options={
  plugins: {
      legend: {
          display: false,
          
          
      },
      
  },
  scales: {
      xAxis: {
          display: true,
          grid: {
              display:false
          }
      },
      yAxis: {
          display: true,
          grid: {
              display:true
          }
      }

  },
  elements: {
      point:{
          radius: 0
      }
  },
  interaction: {
      mode: 'x'
  },
  
  
  
  maintainAspectRatio: false,
 
};
 
  return (
    
    
    <StyledBadge badgeContent={numberofstocks.length} color="secondary">
      <div className='portfoliocards-main-container'>
       
          <div className='portfoliocards-sub-container'>
              <div className='portfoliocards-left'>
                    <div className='portfolio-name-network-containers'>
                      <h4 className="portfolio-name">{props.name}</h4>
                      
                    </div>
                  
                  <div>
                  <Sparklines data={countsy} >
                            <SparklinesCurve color={ props.oneyeargrowth > 0 ? "#3DAB96" : "#DE7D7E"  } style={{ strokeWidth: 3, stroke: "#336aff"  }}/>
                    </Sparklines>

                
                    </div>
              </div>
              <div>
              <p className='onyearpositive'> ${networth  ? networth.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0} </p>
                  <AvatarGroup total={numberofstocks.length}>
                {(props.stocks).slice(0,3).map(p => <Avatar alt={p.Name} key={p.Name} src={p && p.stockss && p.stockss.LogoURL && p.stockss.Type === 'Common Stock' ? ("https://eodhistoricaldata.com" + p.stockss.LogoURL): p && p.stockss  && p.stockss.Type === 'Currency' ? `https://cdn.moning.co/logos/${p && p.stockss && p.stockss.Code}.CC.png` : "https://jobs.yeswehack.com/images/326c988.png"}/>)}
                </AvatarGroup>
              </div>
          </div>

          <div className='portfoliocards-sub-sub-container'>
            <div className='portfolio-stats'>
            <p className='portfolio-stats-title'>One year growth</p>
            <p className='portfolio-onyear'>{props.oneyeargrowth}%</p>
            </div>

            <div className='portfolio-stats'>
            <p className='portfolio-stats-title'>Yearly dividend</p>
            <p className='portfolio-dividend'>${props.yearlydividend}</p>
            </div>

            <div className='portfolio-stats'>
            <p className='portfolio-stats-title'>Dividend yield</p>
            <p className='portfolio-dividend-yield'>{props.dividendyield}%</p>
            </div>
            
          </div>

    </div>
  </StyledBadge>
  
  )
}

export default Portfoliocards;
