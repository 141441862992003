
import React, { useCallback, useState, useEffect, useContext } from "react"
import './Dashboardcards.css'
import Modal from 'react-bootstrap/Modal'
import { UserTriggerContext } from "./UserTriggerContext";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'chart.js/auto';
import { Chart } from 'react-chartjs-2';
import moment from "moment"
import MediaQuery from 'react-responsive';
import { baseurl} from './env';
import { Sparklines, SparklinesLine, SparklinesCurve } from 'react-sparklines';
import updateicon from './img/update.svg'
import { UserPortfolioContext } from './UserPortfolioContext'
import { UserContext } from './UserContext'

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';


function Dashboardcards(props) {
    const [fullscreen, setFullscreen] = useState(true);
    const [show, setShow] = useState(false);
    const [quantity, setQuantity] = useState(props.quantity)
    const [quantitytoadds, setQuantitytoadds] = useState(0)
    const [trigger, setTrigger] = useContext(UserTriggerContext)

    const [activeportfolios, setActiveportfolios] = useContext(UserPortfolioContext);
    const [value, setValue] = useContext(UserContext)


    async function handleAddStock(){
 
        const genericErrorMessage = "Something went wrong! Please try again later."
      
        await fetch(`${baseurl}/addstockportfolio`, {
            method:"POST",
            headers: {
                "content-type": "application/json",
                "x-access-token": localStorage.getItem('token')
            },
            body: JSON.stringify({stocktoadd: props.id, quantity: quantitytoadds, portfolio: value.portfolios[activeportfolios].name })
        })
        .then(res => res.json())
        .then(data => {
            function SuccessfullCreation () {
              
              
              setTrigger(!trigger)
            }
            data.message === "Success" ? SuccessfullCreation () : console.log('Error during creation')
            
        })
      };
      


      async function DeleteStock () {
        await fetch(`${baseurl}/delete`, {
            method:"POST",
            headers: {
                "content-type": "application/json",
                "x-access-token": localStorage.getItem('token')
            },
            body: JSON.stringify({stocktodelete: props.id, portfolio: value.portfolios[activeportfolios].name })
        })
        .then(res => res.json())
        .then(data => {
            function SuccessfullCreation () {
              
              
              setTrigger(!trigger)
            }
            data.message === "Success" ? SuccessfullCreation () : console.log('Error during creation')
            
        })
      }



   

 


    const arrayofstats = props.Eodstats ? props.Eodstats : 0
    const labeldata = arrayofstats ? arrayofstats.map(a => moment(new Date(a.date)).format("MMMM YYYY")) : 0
    const datasetdata = arrayofstats ? arrayofstats.map(a => a.adjusted_close) : 0

    //Data sparklines
    
    const datasparklnes = arrayofstats ? arrayofstats.map(a => a.adjusted_close) :  [0,0,0,0,0,0,0]
    const datasetdataslicebefore = datasparklnes ? datasparklnes.reverse().slice(0, 11) : [0,0,0,0,0,0,0]
    const datasetdataslice = datasetdataslicebefore.reverse()

    const pricetenyearsago = arrayofstats.length > 1 ? arrayofstats[1].adjusted_close : 0
    const maxitem = arrayofstats ? arrayofstats.length : 0
    const pricetoday = arrayofstats.length > 1 ? arrayofstats[maxitem - 1].adjusted_close : 0
   

    const delta = pricetoday - pricetenyearsago
    const tenyearsgrowth = (delta / pricetenyearsago)*100

    
    const maxitemfiveyears = datasetdata ? datasetdata.length : 0
   
    const fiveyearsindex = maxitemfiveyears - 5*12
  
    const pricefiveyearsago = datasetdata && maxitemfiveyears>60 ? datasetdata[fiveyearsindex] : 0
  
    const deltafiveyears = pricetoday - pricefiveyearsago
    const fiveyearsgrowth = (deltafiveyears / pricefiveyearsago)*100


    /// DIVIDENDS
    

    const arrayofstatsdividend = props.DividendHistory ? props.DividendHistory : 0
    const labeldatadividend = arrayofstatsdividend ? arrayofstatsdividend.map(a => moment(new Date(a.date)).format("MMMM Do YYYY")) : 0
    const datasetdatadividend = arrayofstatsdividend ? arrayofstatsdividend.map(a => a.value) : 0


    ///INCOME

    const arrayofstatsIncome_Statementraw = props.Income_Statement ? props.Income_Statement : 0
    const arrayofstatsIncome_Statement = arrayofstatsIncome_Statementraw ? arrayofstatsIncome_Statementraw.reverse() : 0
    const labeldataIncome_Statementraw = arrayofstatsIncome_Statement ? arrayofstatsIncome_Statement.map(a => moment(new Date(a.date)).format("YYYY")) : 0
    const labeldataIncome_Statement = labeldataIncome_Statementraw ? labeldataIncome_Statementraw.reverse() : 0
    const datasetdataIncome_Statementraw = arrayofstatsIncome_Statement ? arrayofstatsIncome_Statement.map(a => a.totalRevenue) : 0
    const datasetdataIncome_Statement = datasetdataIncome_Statementraw ? datasetdataIncome_Statementraw.reverse() : 0


    /// CASHFLOW

    const arrayofstatsCash_Flowraw = props.Cash_Flow ? props.Cash_Flow : 0
    const arrayofstatsCash_Flow = arrayofstatsCash_Flowraw
    const labeldataCash_Flowraw = arrayofstatsCash_Flow ? arrayofstatsCash_Flow.map(a => moment(new Date(a.date)).format("YYYY")) : 0
    const labeldataCash_Flow = labeldataIncome_Statementraw
    const datasetdataCash_Flowraw = arrayofstatsCash_Flow ? arrayofstatsCash_Flow.map(a => a.freeCashFlow) : 0
    const datasetdataCash_Flow = datasetdataCash_Flowraw ?  datasetdataCash_Flowraw.reverse() : 0

   
    

    /////////////////////////// GET TOTAL DIV VALUE OVERTIME CHART ( YEARLY )///////////////


    const finalarrayDivYearly = LoopthroughEODDivYearly() 
  const merge3DivYearly = finalarrayDivYearly.flat(1);  //Here is our array of data
//console.log(merge3DivYearly);


const countsDivYearly = {};        // Array of data final version
merge3DivYearly.forEach((x) => {
  countsDivYearly[x.date] = (countsDivYearly[x.date] || 0) + x.value;
});
//console.log(countsDivYearly)



function sortObjectByKeys(o) {
  return Object.keys(o).sort().reduce((r, k) => (r[k] = o[k], r), {}); /// Function to keep the same order on the array
}
const orderedcountsDivYearly = sortObjectByKeys(countsDivYearly) 
//console.log(orderedcountsDivYearly)

const countsxDivYearly = (Object.keys(orderedcountsDivYearly))
const countsyDivYearly = (Object.values(orderedcountsDivYearly))

//console.log(countsxDivYearly)
//console.log(countsyDivYearly)






function LoopthroughEODDivYearly() {
  const subsub_arrayDivYearly = [];
  const eod = props.DividendHistory 
  
  ////console.log(JSON.stringify(eod))

           
          for (let i = 1; i < eod.length; i++) {  

            var dateObj = new Date(eod[i].date);
            var momentObj = moment(dateObj).format("YYYY");

            
            const resultobject = { date: momentObj, value: (eod[i].value)};
            subsub_arrayDivYearly.push(resultobject);
           
          }
         
          return subsub_arrayDivYearly

        }

   ///////////////////////////////////////////////////////////////////////////////////


    /////////////////////////////// CALCUL DIVIDEND GROWTH ////////////////////////////
    function CalculDividendGrowth (monthlyoryearly) {
        const DividendgrowthArray = [];
    
        for (let i = 2; i < monthlyoryearly.length-1; i++) { 
          
          const delta = monthlyoryearly[i] - monthlyoryearly[i-1]
          const percent = (delta / monthlyoryearly[i-1])*100
          DividendgrowthArray.push(percent);
          
        } return DividendgrowthArray
       }

       
       const arrayofdividendspercent = CalculDividendGrowth(countsyDivYearly)
    
       function add(accumulator, a) {
        return accumulator + a;
      }
       const sumdivids = arrayofdividendspercent.reduce(add, 0);
       
       const PercentageofDividendGrowth = sumdivids/arrayofdividendspercent.length      // % of Dividend growth for the past 10 years
    
    
       ///////////////////////////////////////////////////////////////////////////////////
    




    const options={
        plugins: {
            legend: {
                display: false,
                
                
            },
            
        },
        scales: {
            xAxis: {
                display: true,
                grid: {
                    display:false
                }
            },
            yAxis: {
                display: true,
                grid: {
                    display:true
                }
            }

        },
        elements: {
            point:{
                radius: 0
            }
        },
        interaction: {
            mode: 'x'
        },
        
        
        
        maintainAspectRatio: false,
       
    };

    const [isyearly, setIsyearly] = useState(true)


    //const arrayofstats = props.Eodstats 
    //let resultdate = arrayofstats.map(a => a.date);
    //let resultclose = arrayofstats.map(a => a.close);
      
    

    return (
        <div>

            <Modal show={show}  onHide={() => setShow(false)} className='modal-dashboardcaard'>
            
            <Modal.Header closeButton>
            <Modal.Title><img className="stockcard-image-modal" src={props.image}/><span className="modal-name-title">{props.name}</span> 
            
            </Modal.Title>
            </Modal.Header>
           
            <Modal.Body>

                <div className="modal-body-header">
            <p className="modal-currentprice">{props.Currentprice ? props.Currentprice.toFixed(2) + "$" : "-"}</p>
            <p className="modal-currentprice">{props.Sector}</p>
            
            {props.quantity ? (<p className='modal-currentprice'>You own: {props.quantity} stocks</p> ): null}
            
            </div>


            <div className="modal-description-flex">
            <div className="modal-indicator">
                <h4>PE Ration</h4>
                <p className="stock-info-growth-modal">{props.PERatio.toFixed(2)}</p>
            </div>
            <div className="modal-indicator">
                <h4>Dividend per share</h4>
                <p className="indicator">{props.DividendShare} $ (Yearly)</p>
            </div>
            <div className="modal-indicator">
            <h4>Dividend Yield</h4>
                <p className="indicator">{(props.DividendYield * 100).toFixed(2)}%</p>
            </div>

            
            
            </div>

            <div className="modal-description">
            <div>
            <h4 className="graph-title">Price evolution over 10 years <span className={ tenyearsgrowth > 0 ? "stock-info-growth-modal" : "stock-info-growth-modal-red" }>{Math.floor(tenyearsgrowth)} %</span></h4>
            
            </div>
                
            <div>
            <Chart  options={options}
                    height={300}  type='line' data={{
                    labels: labeldata,
                    datasets: [
                    {
                        id: 1,
                        backgroundColor: '#6e85ea54',
                        fill: true,
                        borderColor: '#6E84EA',
                        label: 'price',
                        data: datasetdata,
                        yAxisID: 'yAxis',
                        xAxisID: 'xAxis'
                    },
                    
                    ],
                    }} 
            />
            </div>
            </div>  
                
           

            <div className="modal-description-flex">

            <div className="modal-indicator">
            <h4 >One Year Growth</h4>
            <p className="stock-info-growth-modal">{Math.floor(props.OneYearGrowth) } %</p>
            </div>

            <div className="modal-indicator">
            <h4>5 Years Growth</h4>
            <p className="stock-info-growth-modal">{Math.floor(fiveyearsgrowth) } %</p>
            </div>

            <div className="modal-indicator">
            <h4>10 Years Growth</h4>
            <p className="stock-info-growth-modal">{Math.floor(tenyearsgrowth) } %</p>
            </div>

            </div>


           


            <div className="modal-description">
            <div>
            <h4 className="graph-title">Dividend evolution</h4>
            <p><span onClick ={() => setIsyearly(true)} className={isyearly === true ? "quantity-amount" :  "quantity-amount-false"}>Yearly / </span ><span onClick ={() => setIsyearly(false)} className={isyearly === false ? "quantity-amount" :  "quantity-amount-false"}>Monthly</span></p>
            <p className="stock-info-growth-modal">Average yearly Dvidend growth: {PercentageofDividendGrowth.toFixed(1)} %</p>
            </div>
                

            {isyearly === false ? (
            <div>
            <Chart  options={options}
                    height={300}  type='bar' data={{
                    labels: labeldatadividend,
                    datasets: [
                    {
                        id: 1,
                        backgroundColor: '#6e85ea54',
                        fill: true,
                        borderColor: '#6E84EA',
                        label: 'price',
                        data: datasetdatadividend,
                        yAxisID: 'yAxis',
                        xAxisID: 'xAxis'
                    },
                    
                    ],
                    }} 
            />
            </div>
            ) : (
            <div>
            <Chart  options={options}
                    height={300}  type='bar' data={{
                    labels: countsxDivYearly,
                    datasets: [
                    {
                        id: 1,
                        backgroundColor: '#6e85ea54',
                        fill: true,
                        borderColor: '#6E84EA',
                        label: 'Yearly dividend USD',
                        data: countsyDivYearly,
                        yAxisID: 'yAxis',
                        xAxisID: 'xAxis'
                    },
                    
                    ],
                    }} 
            />
            </div>
            )}
            </div>

            <MediaQuery minWidth={1000}>


            <div className="modal-graph-half-container">



                        <div className="modal-description-half">
                        <div>
                        <h4 className="graph-title">Total revenue evolution</h4>
                        </div>
                            
                        <div>
                        <Chart  options={options}
                                height={300}  type='bar' data={{
                                labels: labeldataIncome_Statement,
                                datasets: [
                                {
                                    id: 1,
                                    backgroundColor: '#363739',
                                    fill: true,
                                    borderColor: '#E5E3FF',
                                    label: 'Total revenue',
                                    data: datasetdataIncome_Statement,
                                    yAxisID: 'yAxis',
                                    xAxisID: 'xAxis'
                                },
                                
                                ],
                                }} 
                        />
                        </div>
                        </div>

                        <div className="modal-description-half">
                        <div>
                        <h4 className="graph-title">Cash flow evolution</h4>
                        </div>
                            
                        <div>
                        <Chart  options={options}
                                height={300}  type='bar' data={{
                                labels: labeldataCash_Flow,
                                datasets: [
                                {
                                    id: 1,
                                    backgroundColor: '#363739',
                                    fill: true,
                                    borderColor: '#2CBEBA',
                                    label: 'Cash flow',
                                    data: datasetdataCash_Flow,
                                    yAxisID: 'yAxis',
                                    xAxisID: 'xAxis'
                                },
                                
                                ],
                                }} 
                        />
                        </div>
                        </div>

            </div>
            </MediaQuery>

            <MediaQuery maxWidth={1000}>


            <div className="modal-graph-half-container-mobile">



                <div className="modal-description">
                <div>
                <h4 className="graph-title">Total revenue evolution</h4>
                </div>
                    
                <div>
                <Chart  options={options}
                        height={300}  type='bar' data={{
                        labels: labeldataIncome_Statement,
                        datasets: [
                        {
                            id: 1,
                            backgroundColor: '#363739',
                            fill: true,
                            borderColor: '#2CBEBA',
                            label: 'Total revenue',
                            data: datasetdataIncome_Statement,
                            yAxisID: 'yAxis',
                            xAxisID: 'xAxis'
                        },
                        
                        ],
                        }} 
                />
                </div>
                </div>

                <div className="modal-description">
                <div>
                <h4 className="graph-title">Cash flow evolution</h4>
                </div>
                    
                <div>
                <Chart  options={options}
                        height={300}  type='bar' data={{
                        labels: labeldataCash_Flow,
                        datasets: [
                        {
                            id: 1,
                            backgroundColor: '#363739',
                            fill: true,
                            borderColor: '#2CBEBA',
                            label: 'Cash flow',
                            data: datasetdataCash_Flow,
                            yAxisID: 'yAxis',
                            xAxisID: 'xAxis'
                        },
                        
                        ],
                        }} 
                />
                </div>
                </div>

                </div>


            </MediaQuery>



            <div className="modal-description">
                <h4>About {props.name}</h4>
                {props.description}
            </div> 
            
            
            </Modal.Body>
            <div className="modal-stats-container">

            </div>
            </Modal>

        <div className="stockcard-container-dashboard" >
            
    
            

            <div className="clickable-section-dashboard" >

            <div className="name-code-container" onClick={() => setShow(true)}>
            <div className="dashboard-image-container">
                <img className="stockcard-image" src={props.image}/>
                
            </div>
            
            <h1 className="stock-name">{props.code}</h1>                 
            <p className="stock-code">{props.name}</p>
            
            
            
            </div>

            <div className="name-code-container" onClick={() => setShow(true)}>
            <p className={ props.OneYearGrowth > 0 ? "oneyear-growth-dashboard" : "oneyear-growth-dashboard-negative" }>{props.OneYearGrowth > 0 ? "+" : null}{Math.floor(props.OneYearGrowth)} %</p>
           
            
            <Sparklines data={datasetdataslice} >
                <SparklinesCurve color={ props.OneYearGrowth > 0 ? "#3DAB96" : "#DE7D7E"  } style={{ strokeWidth: 2, stroke: "#336aff"  }}/>
            </Sparklines>
            
            
            
            </div>
            
            <div className="card-price-growth-container" onClick={() => setShow(true)}>
            <p className="modal-currentprice-dashboard">{props.TotalPosition ? props.TotalPosition.toFixed(2) : ''}$</p>
            <p className="modal-currentprice-dashboard-currentprice">{props.Currentprice ? props.Currentprice.toFixed(2) : "-"}$</p>
             </div>

            <div onClick={() => setShow(true)}>
            <p className="stock-code">Yearly dividend</p>
            <p className="stock-info">{(props.DividendShare * props.quantity).toFixed(2)}$</p>
            </div>

            <div onClick={() => setShow(true)}>
            <p className="stock-code">PE Ratio</p>
            <p className="stock-info">{props.PERatio.toFixed(2)}</p>
            </div>
           
            <div onClick={() => setShow(true)}>
            <p className="stock-code">Dividend Growth</p>
            <p className="stock-info">{PercentageofDividendGrowth.toFixed(1)}%</p>
            </div>
            
            <div onClick={() => setShow(true)}>
            <p className="stock-code">DividendYield</p>
           
            <p className="stock-info">{(props.DividendYield * 100).toFixed(2)}%</p>
            
            </div>

            <div className="dashboard-ad-button">
                    <input className="quantity-input" defaultValue={quantity} onChange={e => setQuantitytoadds(e.target.value)}></input>
                <button className='addtoportofolio-add-dashboard' onClick={handleAddStock}>Update</button>
                <button className='addtoportofolio-delete-dashboard' onClick={DeleteStock}>x</button>
                
            </div>

        

            </div>
            
        </div>
       
      
        </div>
    )
}

export default Dashboardcards
