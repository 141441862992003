import React, { useContext, useState, useEffect } from "react"

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router'
import { baseurl } from "./env";
import { GoogleLogout, GoogleLogin } from 'react-google-login'
import GoogleIcon from '@mui/icons-material/Google';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://peakfi.co">
        PeakFi.co
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

export default function SignIn(props) {


    const navigate = useNavigate()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [error, setError] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [status, setStatus] = useState("Sign in")
  const [data, setdata] = useState(null)



  async function handleSubmit(e){
    e.preventDefault();
    
    setIsSubmitting(true)
    setError("")

    const form = e.target;

        const user =  {
            email: email,
            password: password
        }
    
    // eslint-disable-next-line no-console
    const genericErrorMessage = "Something went wrong! Please try again later."

    await fetch(`${baseurl}/login`, {
        method:"POST",
        headers: {
            "content-type": "application/json"
        },
        body: JSON.stringify(user)
    })
    .then(res => res.json())
    .then(data => {
      console.log(data)
        localStorage.setItem("token", data.token)
        
        data.message === "Success" ? navigate('/dashboard'): setError(data.message) 
        console.log(localStorage)
    })
  };




  const handleLogin = async googleData => {
    const res = await fetch(`${baseurl}/api/v1/auth/google`, {
        method: "POST",
        body: JSON.stringify({
        token: googleData.tokenId
      }),
      headers: {
        "Content-Type": "application/json"
      }
    })
    .then(res => res.json())
    .then(data => {
        console.log(data)
          localStorage.setItem("token", data.token)
          
          data.message === "Success" ? navigate('/dashboard'): setError('Incorrect password') 
          console.log(localStorage)
      })
    
    
    // store returned user somehow
  }

  useEffect(() => {
      fetch(`${baseurl}/isAuth`, {
          method:"GET",
          headers: {
              "x-access-token": localStorage.getItem('token')
          }
      })
      .then(res => res.json())
      .then(data => data.isLoggedIn === true ? navigate('/dashboard'): null)
  }, [])




  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          {props.success === true ? <Alert severity="success">
              <AlertTitle>Success !</AlertTitle>
              Your account has been successfully created! — <strong>Sign in to get started!</strong>
            </Alert> : null}
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={e => setEmail(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={e => setPassword(e.target.value)}
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            
            <Button
              
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>

            {error ? <Alert severity="error">
        <AlertTitle>Error</AlertTitle>
        {error} — <strong>Try again!</strong>
      </Alert> : null }

            <div className="google-button">
                <div className="google-sub"></div>
                <GoogleLogin
                      theme="dark"
                      clientId={'206368780753-e2qo3s2tle0gj97f3pur615qb5grf7ne.apps.googleusercontent.com'}
                      buttonText="Sign up with Google"
                      onSuccess={handleLogin}
                      onFailure={handleLogin}
                      cookiePolicy={'single_host_origin'}
                      render={(renderProps) => (
                        <Button onClick={renderProps.onClick} disabled={renderProps.disabled}
                        fullWidth
                        variant="outlined"
                        
                        sx={{ mt: 3, mb: 2 }}>
                          <GoogleIcon fontSize="small" className="googleicon"/> Sign In with Google
                        </Button>
                      )}
                  />
            </div>


            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link  variant="body2" onClick={()=> props.setTab('register')}>
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}