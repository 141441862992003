import React from 'react'
import './Success.css'
import { Routes, Route, Link } from "react-router-dom";

function Success() {
    return (
        <div>
            <div className='success-container'>
            <h1>Great sam, everything is ready.</h1>
            <p>Thank you for your payment. You're now ready to track your investment portfolio the smart way.</p>
            <button className='go-dashboard'><Link   to="/dashboard"><p className='go-p'>Go to your dashboard</p></Link></button>
            </div>
           
        </div>
    )
}

export default Success
