import React, {useState, createContext, useEffect, useContext} from 'react';
import { baseurl } from "./env"
import { UserTabContext } from './UserTabContext'
import { UserTriggerContext } from './UserTriggerContext';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';


export const UserContext = createContext();

export const UserProvider = (props) => {


    const [dashboard, setDashboard] = useState([''])
    const [portfolios, setPortfolios] = useState([''])
    const [stocks, setStocks] = useState([''])
    const [value, setValue] = useState([''])
    const [selectedIndex, setSelectedIndex] = useContext(UserTabContext);
    const [trigger, setTrigger] = useContext(UserTriggerContext)
    const [open, setOpen] = React.useState(false);

    const fetchData = async () => {
      setOpen(true)
      console.log('UserContext strart')
        await fetch(`${baseurl}/userdashboard`, {
          method:"GET",
          headers: {
              "x-access-token": localStorage.getItem('token')
          }
        })
          .then((response) => response.json())
          .then((result) => {
            setValue(result)
            
            setOpen(false)
            console.log('UserContext end')
          })
      }
    
      useEffect(() => {
        fetchData();
         
    }, [trigger])



    

return (
<UserContext.Provider  value={[value, setValue]} >
<Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        
      >
        <CircularProgress color="inherit" />
      </Backdrop>
 {props.children}
</UserContext.Provider>
);

}
