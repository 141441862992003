import React from 'react'
import './Billing.css'

function Billing() {
    return (
        <div>
            <div className='billing-container'>
                <h1>Billing</h1>

            </div>
        </div>
    )
}

export default Billing
