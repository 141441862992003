import React, {useState, createContext, useEffect} from 'react';


export const UserPortfolioContext = createContext();

export const UserPortfolioProvider = (props) => {

    const [activeportfolios, setActiveportfolios] = useState([0])
    
return (
<UserPortfolioContext.Provider  value={[activeportfolios, setActiveportfolios]} >
 {props.children}
</UserPortfolioContext.Provider>
);

}