import React from 'react'
import './Invoices.css'

function Invoices() {
    return (
        <div>
            <div className='billing-container'>
            <p className='portfolio-dividend-yield'>Invoices</p>
                <h3>You don't have any invoice yet.</h3>

            </div>
        </div>
    )
}

export default Invoices