import React from 'react'
import './Admin.css'
import { useState, useEffect } from 'react';
import { baseurl } from "./env"

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';




function Admin() {


    const [users, setusers] = useState('')


    const fetchData = async () => {
        
          await fetch(`${baseurl}/allusers`, {
            method:"GET",
            
          })
            .then((response) => response.json())
            .then((result) => {
                setusers(result.users) 
            })
        }
      
        useEffect(() => {
          fetchData();
           
      }, [])




    const columns = [
        {
            id: 'density',
            label: '',
            
            
          },

        
        { id: 'name', label: 'Name', minWidth: 170 },
        { id: 'code', label: 'Email', minWidth: 100 },
        {
          id: 'population',
          label: 'Plan',
          
          format: (value) => value.charAt(0).toUpperCase(),
        },
        {
          id: 'size',
          label: 'Last edit',
          
          format: (value) => value.toLocaleString('en-US'),
        },
       
      ];
      
      function createData(density, name, code, population, size) {
        
        return { density, name, code, population, size };
      }

      

      const rows = users ? (users.map(x =>
        createData( <img className='profilepicture' src={x.picture}/>, x.firstname, x.email , <p className= {x.plan === 'free' ? 'plan-free' : 'plan-premium'}>{x.plan}</p>, x.updatedAt ),
        ) ) : null

      /*
      const rows = [
        
        createData('India', 'IN', 1324171354, 3287263),
        createData('China', 'CN', 1403500365, 9596961),
        createData('Italy', 'IT', 60483973, 301340),
        createData('United States', 'US', 327167434, 9833520),
        createData('Canada', 'CA', 37602103, 9984670),
        createData('Australia', 'AU', 25475400, 7692024),
        createData('Germany', 'DE', 83019200, 357578),
        createData('Ireland', 'IE', 4857000, 70273),
        createData('Mexico', 'MX', 126577691, 1972550),
        createData('Japan', 'JP', 126317000, 377973),
        createData('France', 'FR', 67022000, 640679),
        createData('United Kingdom', 'GB', 67545757, 242495),
        createData('Russia', 'RU', 146793744, 17098246),
        createData('Nigeria', 'NG', 200962417, 923768),
        createData('Brazil', 'BR', 210147125, 8515767),

      ];
      */

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };
  

    
    return (
        <div>
 {users ? (
            <div>
            <div className='billing-container'>
            <p className='portfolio-dividend-yield'>Admin</p>
            <div className='customers-stats-container'>
                <p className='customer-stat'>Customers ({users.length})</p>
                <p className='customer-stat'>Premium ({(users.filter(x => x.plan === 'premium')).length})</p>
                <p className='customer-stat'>Free ({(users.filter(x => x.plan === 'free')).length})</p>
            </div>
            

            </div>

           

            <Paper sx={{ width: '90%', margin:'auto' }}>
                <TableContainer sx={{ maxHeight: 1040 }}>
                    <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                        <TableCell align="center" colSpan={2}>
                            Country
                        </TableCell>
                        <TableCell align="center" colSpan={3}>
                            Details
                        </TableCell>
                        </TableRow>
                        <TableRow>
                        {columns.map((column) => (
                            <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ top: 57, minWidth: column.minWidth }}
                            >
                            {column.label}
                            </TableCell>
                        ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row) => {
                            return (
                            <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                {columns.map((column) => {
                                const value = row[column.id];
                                return (
                                    <TableCell key={column.id} align={column.align}>
                                    {column.format && typeof value === 'number'
                                        ? column.format(value)
                                        : value}
                                    </TableCell>
                                );
                                })}
                            </TableRow>
                            );
                        })}
                    </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
             </Paper>
             </div>


) : null}
        </div>
    )
}

export default Admin