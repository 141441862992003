import React from 'react'
import {useState} from 'react';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link
  } from "react-router-dom";
import Success from './Success.js'
import Topbar from './Components/Topbar.js'
import App from "./App"
import Auth from './Auth'
import Dashboard from './Dashboard'
import Register from './Register.js';
import Authregister from './Authregister.js';
import Etfs from './Etfs.js';
import Indices from './Indices.js';
import Home from './Home.js';
import Crypto from './Crypto'
import Pricing from "./Pricing"
import Billing from './Billing';
import Leftmenu from "./Leftmenu"
import {UserTabProvider} from './UserTabContext'
import SignInfrom from './SignIn.js';
import SignIn from './SignIn.js';


function Routings() {



    return (
        <div>
            <Routes>
             
                <Route path="/" element={<Home />} />
                <Route path="/stocks" element={<App />} />
                <Route path="/crypto" element={<Crypto />} />
                <Route path="/indices" element={<Indices />} />
                <Route path="/pricing" element={<Pricing />} />
                <Route path="/etfs" element={<Etfs />} />
                <Route path="/billing" element={<Billing />} />
                <Route path="/signin" element={<SignIn />} />
                <Route path="/auth" element={<Auth />} />
                <Route path="/success" element={<Success />} />
                <Route path="/auth/register" element={<Authregister />} />
                <Route path="/dashboard" element={<Leftmenu />} />
               
            </Routes> 
        </div>
    )
}

export default Routings
