import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { UserProvider } from "./UserContext"
import Topbar from './Components/Topbar';
import Routing from './Routing'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import {UserTabProvider} from './UserTabContext'
import {UserPortfolioProvider} from './UserPortfolioContext'
import { UserTriggerProvider } from './UserTriggerContext';


ReactDOM.render(
  <React.StrictMode>
    
    
    <Router>
      
    <UserTabProvider>
      <UserPortfolioProvider>
        <UserTriggerProvider>
    <Topbar/>
    <UserProvider>
    <Routing/>
    </UserProvider>
    </UserTriggerProvider>
    </UserPortfolioProvider>
    </UserTabProvider>
    
    </Router>
   
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
