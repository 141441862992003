import React, { useContext, useState } from "react"
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Links from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Link from '@mui/material/Link';
import { useNavigate } from 'react-router'
import { baseurl } from "./env";
import { GoogleLogout, GoogleLogin } from 'react-google-login'
import Login from "./Login";
import GoogleIcon from '@mui/icons-material/Google';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://peakfi.co/">
        Peakfi.co
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

export default function SignUp(props) {

    const [error, setError] = useState("")
    const navigate = useNavigate()


    const [isSubmitting, setIsSubmitting] = useState(false)

  const [firstname, setFirstName] = useState("")
  const [lastname, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [status, setStatus] = useState("Register")
  const [registerdata, setRegisterdata ] = useState('')


async function handleSubmit(e){
     e.preventDefault();
     setStatus("Register ...")
     setError('')
     setIsSubmitting(true)
      const data = new FormData(e.currentTarget);
      // eslint-disable-next-line no-console
      console.log({
        email: data.get('email'),
        password: data.get('password'),
      });

      const email = data.get('email')
      const pw = data.get('password')
      const firstnm = data.get('firstName')
      const lastnm = data.get('lastName')

      
    
    
   

    const genericErrorMessage = "Something went wrong! Please try again later."

    await fetch(`${baseurl}/register`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ firstname, lastname, email: email, password }),
    })
    .then(res => res.json())
    .then(data => {
      function Success () {
        
        props.setTab('login')
        props.setSuccess(true)
      }
      

      data.message === "Success" ? Success(): setError(data.message) 
        

      /*
      setRegisterdata(data)
        if (data.message === "Success"){
          
          navigate('/dashboard')

        } else if (data.message === "Email has already been taken") {
          setError(data.message)
          setStatus("Register")
          
        }

        */
        
    })
/*

     if (registerdata.message === "Success") {
      await navigate('/auth')
     } else {
      await setError(data.message)
     } 


*/


    };


    const handleLogin = async googleData => {
        const res = await fetch(`${baseurl}/api/v1/auth/google`, {
            method: "POST",
            body: JSON.stringify({
            token: googleData.tokenId
          }),
          headers: {
            "Content-Type": "application/json"
          }
        })
        .then(res => res.json())
        .then(data => {
            console.log(data)
              localStorage.setItem("token", data.token)
              
              data.message === "Success" ? navigate('/dashboard'): setError('Incorrect password') 
              console.log(localStorage)
          })
        
        
        // store returned user somehow
      }
      
  
    return (
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign up
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    autoComplete="given-name"
                    name="firstName"
                    required
                    fullWidth
                    id="firstName"
                    label="First Name"
                    autoFocus
                    onChange={e => setFirstName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    id="lastName"
                    label="Last Name"
                    name="lastName"
                    autoComplete="family-name"
                    onChange={e => setLastName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    onChange={e => setEmail(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="new-password"
                    onChange={e => setPassword(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={<Checkbox value="allowExtraEmails" color="primary" />}
                    label="I want to receive inspiration, marketing promotions and updates via email."
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign Up
              </Button>

              {error ? <Alert severity="error">
                            <AlertTitle>Error</AlertTitle>
                        {error} — <strong>Try again</strong>
                      </Alert> : null }

              <div className="google-button">
                <div className="google-sub"></div>
                <GoogleLogin
                      theme="dark"
                      clientId={'206368780753-e2qo3s2tle0gj97f3pur615qb5grf7ne.apps.googleusercontent.com'}
                      buttonText="Sign up with Google"
                      onSuccess={handleLogin}
                      onFailure={handleLogin}
                      cookiePolicy={'single_host_origin'}
                      render={(renderProps) => (
                        <Button onClick={renderProps.onClick} disabled={renderProps.disabled}
                        fullWidth
                        variant="outlined"
                        
                        sx={{ mt: 3, mb: 2 }}>
                          <GoogleIcon fontSize="small" className="googleicon"/> Sign Up with Google
                        </Button>
                      )}
                  />
            </div>

            <Grid item>
                  <Link href="/auth" variant="body2">
                    Already have an account? Sign in
                  </Link>
                </Grid>


              
            </Box>
          </Box>
        
          <Copyright sx={{ mt: 5 }} />
        </Container>
      </ThemeProvider>
    );
  }