import React from 'react'
import './Stockcard.css'
import Modal from 'react-bootstrap/Modal'
import { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'chart.js/auto';
import { Chart } from 'react-chartjs-2';
import moment from "moment"
import MediaQuery from 'react-responsive';
import { baseurl} from '../env';
import { Sparklines, SparklinesLine, SparklinesCurve } from 'react-sparklines';


function Stockcard(props) {
    const [fullscreen, setFullscreen] = useState(true);
    const [show, setShow] = useState(false);
    const [quantity, setQuantity] = useState(1)



   

 


    const arrayofstats = props.Eodstats ? props.Eodstats : 0
    const labeldata = arrayofstats ? arrayofstats.map(a => moment(new Date(a.date)).format("MMMM YYYY")) : 0
    const datasetdata = arrayofstats ? arrayofstats.map(a => a.adjusted_close) : 0

    //Data sparklines
    
    const datasparklnes = arrayofstats ? arrayofstats.map(a => a.adjusted_close) :  [0,0,0,0,0,0,0]
    const datasetdataslicebefore = datasparklnes ? datasparklnes.reverse().slice(0, 11) : [0,0,0,0,0,0,0]
    const datasetdataslice = datasetdataslicebefore.reverse()

    const pricetenyearsago = arrayofstats.length > 1 ? arrayofstats[1].adjusted_close : 0
    const maxitem = arrayofstats ? arrayofstats.length : 0
    const pricetoday = arrayofstats.length > 1 ? arrayofstats[maxitem - 1].adjusted_close : 0
   

    const delta = pricetoday - pricetenyearsago
    const tenyearsgrowth = (delta / pricetenyearsago)*100

    
    const maxitemfiveyears = datasetdata ? datasetdata.length : 0
   
    const fiveyearsindex = maxitemfiveyears - 5*12
  
    const pricefiveyearsago = datasetdata && maxitemfiveyears>60 ? datasetdata[fiveyearsindex] : 0
  
    const deltafiveyears = pricetoday - pricefiveyearsago
    const fiveyearsgrowth = (deltafiveyears / pricefiveyearsago)*100


    /// DIVIDENDS
    

    const arrayofstatsdividend = props.DividendHistory ? props.DividendHistory : 0
    const labeldatadividend = arrayofstatsdividend ? arrayofstatsdividend.map(a => moment(new Date(a.date)).format("MMMM Do YYYY")) : 0
    const datasetdatadividend = arrayofstatsdividend ? arrayofstatsdividend.map(a => a.value) : 0


    ///INCOME

    const arrayofstatsIncome_Statementraw = props.Income_Statement ? props.Income_Statement : 0
    const arrayofstatsIncome_Statement = arrayofstatsIncome_Statementraw ? arrayofstatsIncome_Statementraw.reverse() : 0
    const labeldataIncome_Statementraw = arrayofstatsIncome_Statement ? arrayofstatsIncome_Statement.map(a => moment(new Date(a.date)).format("YYYY")) : 0
    const labeldataIncome_Statement = labeldataIncome_Statementraw ? labeldataIncome_Statementraw.reverse() : 0
    const datasetdataIncome_Statementraw = arrayofstatsIncome_Statement ? arrayofstatsIncome_Statement.map(a => a.totalRevenue) : 0
    const datasetdataIncome_Statement = datasetdataIncome_Statementraw ? datasetdataIncome_Statementraw.reverse() : 0


    /// CASHFLOW

    const arrayofstatsCash_Flowraw = props.Cash_Flow ? props.Cash_Flow : 0
    const arrayofstatsCash_Flow = arrayofstatsCash_Flowraw
    const labeldataCash_Flowraw = arrayofstatsCash_Flow ? arrayofstatsCash_Flow.map(a => moment(new Date(a.date)).format("YYYY")) : 0
    const labeldataCash_Flow = labeldataIncome_Statementraw
    const datasetdataCash_Flowraw = arrayofstatsCash_Flow ? arrayofstatsCash_Flow.map(a => a.freeCashFlow) : 0
    const datasetdataCash_Flow = datasetdataCash_Flowraw ?  datasetdataCash_Flowraw.reverse() : 0

   
    

/*

async function AddToPorofolio() {

    const stock ={ 
                    stocktoadd: props.id,
                    quantity : quantity 
                }


    await fetch(`${baseurl}/add`, {
            method:"POST",
            headers: {
                "content-type": "application/json",
                "x-access-token": localStorage.getItem('token')
            },
            body: JSON.stringify(stock)
        })
        .then(res => res.json())
       
       

       
    }

   

*/
    




    const options={
        plugins: {
            legend: {
                display: false,
                
                
            },
            
        },
        scales: {
            xAxis: {
                display: true,
                grid: {
                    display:false
                }
            },
            yAxis: {
                display: true,
                grid: {
                    display:true
                }
            }

        },
        elements: {
            point:{
                radius: 0
            }
        },
        interaction: {
            mode: 'x'
        },
        
        
        
        maintainAspectRatio: false,
       
    };

   


    //const arrayofstats = props.Eodstats 
    //let resultdate = arrayofstats.map(a => a.date);
    //let resultclose = arrayofstats.map(a => a.close);
      
    

    return (
        <div>

            <Modal show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>
            
            <Modal.Header closeButton>
            <Modal.Title><img className="stockcard-image-modal" src={props.image}/><span className="modal-name-title">{props.name}</span> 
            <div>
            <button className='addtoportofolio-add' onClick={() => setQuantity(quantity - 1)}>-</button><button className='addtoportofolio'>{quantity}</button><button className='addtoportofolio-add' onClick={() => setQuantity(quantity + 1)}>+</button><button className='addtoportofolio' onClick={()=>props.AddToPorofolio(props.id, quantity)}>Add / Remove from my portofolio</button>
            </div>
            </Modal.Title>
            </Modal.Header>
           
            <Modal.Body>

                <div className="modal-body-header">
            <p className="modal-currentprice">{props.Currentprice ? props.Currentprice.toFixed(2) + "$" : "-"}</p>
            <p className="modal-currentprice">{props.Sector}</p>
            
            </div>


            <div className="modal-description-flex">
            <div className="modal-indicator">
                <h4>PE Ration</h4>
                <p className="stock-info-growth-modal">{props.PERatio.toFixed(2)}</p>
            </div>
            <div className="modal-indicator">
                <h4>Dividend per share</h4>
                <p className="indicator">{props.DividendShare} $ (Yearly)</p>
            </div>
            <div className="modal-indicator">
            <h4>Dividend Yield</h4>
                <p className="indicator">{(props.DividendYield * 100).toFixed(2)}%</p>
            </div>

            
            
            </div>

            <div className="modal-description">
            <div>
            <h4 className="graph-title">Price evolution over 10 years <span className={ tenyearsgrowth > 0 ? "stock-info-growth-modal" : "stock-info-growth-modal-red" }>{Math.floor(tenyearsgrowth)} %</span></h4>
            
            </div>
                
            <div>
            <Chart  options={options}
                    height={300}  type='line' data={{
                    labels: labeldata,
                    datasets: [
                    {
                        id: 1,
                        backgroundColor: '#40ac97a8',
                        fill: true,
                        borderColor: '#40AC97',
                        label: 'price',
                        data: datasetdata,
                        yAxisID: 'yAxis',
                        xAxisID: 'xAxis'
                    },
                    
                    ],
                    }} 
            />
            </div>
            </div>  
                
           

            <div className="modal-description-flex">

            <div className="modal-indicator">
            <h4 >One Year Growth</h4>
            <p className="stock-info-growth-modal">{Math.floor(props.OneYearGrowth) } %</p>
            </div>

            <div className="modal-indicator">
            <h4>5 Years Growth</h4>
            <p className="stock-info-growth-modal">{Math.floor(fiveyearsgrowth) } %</p>
            </div>

            <div className="modal-indicator">
            <h4>10 Years Growth</h4>
            <p className="stock-info-growth-modal">{Math.floor(tenyearsgrowth) } %</p>
            </div>

            </div>


           


            <div className="modal-description">
            <div>
            <h4 className="graph-title">Dividend evolution</h4>
            </div>
                
            <div>
            <Chart  options={options}
                    height={300}  type='line' data={{
                    labels: labeldatadividend,
                    datasets: [
                    {
                        id: 1,
                        backgroundColor: '#e5e3ff',
                        fill: true,
                        borderColor: '#aaa7d1',
                        label: 'price',
                        data: datasetdatadividend,
                        yAxisID: 'yAxis',
                        xAxisID: 'xAxis'
                    },
                    
                    ],
                    }} 
            />
            </div>
            </div>

            <MediaQuery minWidth={1000}>


            <div className="modal-graph-half-container">



                        <div className="modal-description-half">
                        <div>
                        <h4 className="graph-title">Total revenue evolution</h4>
                        </div>
                            
                        <div>
                        <Chart  options={options}
                                height={300}  type='bar' data={{
                                labels: labeldataIncome_Statement,
                                datasets: [
                                {
                                    id: 1,
                                    backgroundColor: '#363739',
                                    fill: true,
                                    borderColor: '#E5E3FF',
                                    label: 'Total revenue',
                                    data: datasetdataIncome_Statement,
                                    yAxisID: 'yAxis',
                                    xAxisID: 'xAxis'
                                },
                                
                                ],
                                }} 
                        />
                        </div>
                        </div>

                        <div className="modal-description-half">
                        <div>
                        <h4 className="graph-title">Cash flow evolution</h4>
                        </div>
                            
                        <div>
                        <Chart  options={options}
                                height={300}  type='bar' data={{
                                labels: labeldataCash_Flow,
                                datasets: [
                                {
                                    id: 1,
                                    backgroundColor: '#363739',
                                    fill: true,
                                    borderColor: '#2CBEBA',
                                    label: 'Cash flow',
                                    data: datasetdataCash_Flow,
                                    yAxisID: 'yAxis',
                                    xAxisID: 'xAxis'
                                },
                                
                                ],
                                }} 
                        />
                        </div>
                        </div>

            </div>
            </MediaQuery>

            <MediaQuery maxWidth={1000}>


            <div className="modal-graph-half-container-mobile">



                <div className="modal-description">
                <div>
                <h4 className="graph-title">Total revenue evolution</h4>
                </div>
                    
                <div>
                <Chart  options={options}
                        height={300}  type='bar' data={{
                        labels: labeldataIncome_Statement,
                        datasets: [
                        {
                            id: 1,
                            backgroundColor: '#363739',
                            fill: true,
                            borderColor: '#2CBEBA',
                            label: 'Total revenue',
                            data: datasetdataIncome_Statement,
                            yAxisID: 'yAxis',
                            xAxisID: 'xAxis'
                        },
                        
                        ],
                        }} 
                />
                </div>
                </div>

                <div className="modal-description">
                <div>
                <h4 className="graph-title">Cash flow evolution</h4>
                </div>
                    
                <div>
                <Chart  options={options}
                        height={300}  type='bar' data={{
                        labels: labeldataCash_Flow,
                        datasets: [
                        {
                            id: 1,
                            backgroundColor: '#363739',
                            fill: true,
                            borderColor: '#2CBEBA',
                            label: 'Cash flow',
                            data: datasetdataCash_Flow,
                            yAxisID: 'yAxis',
                            xAxisID: 'xAxis'
                        },
                        
                        ],
                        }} 
                />
                </div>
                </div>

                </div>


            </MediaQuery>



            <div className="modal-description">
                <h4>About {props.name}</h4>
                {props.description}
            </div> 
            
            
            </Modal.Body>
            <div className="modal-stats-container">

            </div>
            </Modal>

        <div className="stockcard-container" onClick={() => setShow(true)}>
            
    
            <div className="stockcard-image-container" >
                <div>
                <img className="stockcard-image" src={props.image}/>
                </div>
                
            </div>

            <div className="name-code-container">
            
            <h1 className="stock-name">{props.code}</h1>                 
            <p className="stock-code">{props.name}</p>
            
            
            
            </div>

            <div className="name-code-container">
            
            <Sparklines data={datasetdataslice} >
                <SparklinesCurve color={ props.OneYearGrowth > 0 ? "#2962FF" : "#DE7D7E"  } style={{ strokeWidth: 5, stroke: "#336aff"  }}/>
            </Sparklines>
            
            
            
            </div>
            
            <div className="card-price-growth-container">
            <p className="modal-currentprice-home">{props.Currentprice ? props.Currentprice.toFixed(2) : "-"}$</p>
            <p className={ props.OneYearGrowth > 0 ? "stock-info-growth" : "stock-info-growth-red" }>{props.OneYearGrowth > 0 ? "+" : null}{Math.floor(props.OneYearGrowth)} %</p>
            </div>

            <div>
            <p className="stock-code">DividendShare</p>
            <p className="stock-info">{props.DividendShare}</p>
            </div>

            <div>
            <p className="stock-code">PE Ratio</p>
            <p className="stock-info">{props.PERatio.toFixed(2)}</p>
            </div>
           
            <div>
            <p className="stock-code">EarningsShare</p>
            <p className="stock-info">{props.EarningsShare.toFixed(2)}</p>
            </div>
            
            <div>
            <p className="stock-code">DividendYield</p>
            <p className="stock-info">{(props.DividendYield * 100).toFixed(2)}%</p>
            
            </div>

        

            <div>
            <p className="stock-code">10 Years growth</p>
            <p className={ tenyearsgrowth > 0 ? "stock-info-growth" : "stock-info-growth-red" }>{Math.floor(tenyearsgrowth)} %</p>
            </div>
        </div>


        </div>
    )
}

export default Stockcard
