import React from 'react'
import './Footer.css'
import logo from './img/peakfi-dark2.png'
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';


function Footer() {
    return (
        <div>
            <div className='footer-container'>
                <div className='footer-column'>
                    <div className='logo-container'>
                    <img className='logo-footer' src={logo}/>
                    </div>
                    <div className='social-icons'>
                    <div>
                    <FacebookIcon  fontSize='large'/>
                    </div>
                    <div>
                    <TwitterIcon fontSize='large'/>
                    </div>
                    <div>
                    <LinkedInIcon fontSize='large'/>
                    </div>
                    </div>
                    
                </div>
                <div className='footer-column'>
                    <h2>About</h2>
                    <p>About PeakFi</p>
                    <p>Blog</p>
                </div>
                <div className='footer-column'>
                    <h2>Product</h2>
                    <p>Pricing</p>
                    <p>Reviews</p>
                    <p>Public portfolios</p>
                </div>
                <div className='footer-column'>
                    <h2>Account</h2>
                    <p>Login</p>
                    <p>Dashboard</p>
                    <p>Register</p>
                </div>

            </div>
        </div>
    )
}

export default Footer