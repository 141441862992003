import { useState, useEffect } from 'react';
import Stockcard from './Components/Stockcard';
import Slider, { Range, SliderTooltip } from 'rc-slider';
import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
import MediaQuery from 'react-responsive';
import Lottie from 'react-lottie';
import * as animationData from './img/stockloading.json'
import Loader from './Components/Loader'
import Login from "./Login"
import Register from "./Register"
import Auth from './Auth'
import { baseurl } from './env';
import './Etfs.css'

function Indices() {

  
  const [stock, setStock] = useState([""]);
  const [query, setQuery] = useState({})
  const [filter, setFilter] = useState('false')
  const [peration, setPeration] = useState([0,1000])
  const [price, setPrice] = useState([0,5000])
  const [dividend, setDividend] = useState([0, 100])
  const [loading, setLoading] = useState(undefined)
  const [page, setPage] = useState(1)
  const [orderby, setOrderby] = useState('EtfYield')
  const [currentTab, setCurrentTab] = useState("login")



  



  async function AddToPorofolio(stocktoadd, quantity) {

    const stock ={ 
                    stocktoadd: stocktoadd,
                    quantity : quantity 
                }
  
  
    await fetch(`${baseurl}/add`, {
            method:"POST",
            headers: {
                "content-type": "application/json",
                "x-access-token": localStorage.getItem('token')
            },
            body: JSON.stringify(stock)
        })
       
  
        fetchData()
   
       
    }
  
 




  const { createSliderWithTooltip } = Slider;
const Range = createSliderWithTooltip(Slider.Range);
const { Handle } = Slider;

const defaultOptions1 = {
  loop: true,
  autoplay: true, 
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};





  const fetchData = async () => {
    setLoading(true)
    fetch(`${baseurl}/indice/search/${query}/?page=${page}&orderby=${orderby}`)
      .then((response) => response.json())
      .then((result) => {
        setStock(result.nasdaqstock)
        setLoading(false)
       
        
      })
  }

  useEffect(() => {
      fetchData();
      
}, [query, price, dividend, page, orderby])




  return (

    
    <>
      
    <div className="App">

            <div className="search-bar-main-container">
              <div className="search-filter-flex">
                  <div className="searchbar-container">
                    <input className="input" placeholder="🔍 Search company" onChange={event => setQuery(event.target.value.length >= 0 ? event.target.value : {})}></input>
                    <button className="clear" onClick={() => setQuery({})}>clear</button>
                  </div>
                  <div className="filter-container">
                    <button className="filter-button" onClick={() => setFilter(true)}>Filters</button>

                  </div>
              </div>
              <div className="custom-select">
                    <select id="lang" onChange={event => setOrderby(event.target.value)} value={orderby}>
                        <option value="OneYearGrowth" >One Year Growth</option>
                        <option value="EtfYield">Dividend Yield</option>
                        <option value="MarketCapitalization">MarketCapitalization</option>
                        <option value="PERatio">PE Ratio</option>
                        <option value="EarningsShare">Earnings Share</option>
                    </select> 
              </div>
            </div>

            { filter === true ?  <div className="edit-box">
              <button className="close-button-edit-box-finescore" onClick={() => setFilter(false)}>X</button>
              
              
              <MediaQuery minWidth={768}>
              <div className="editbox-main-container">

              
            
                <div className='edit-box-container'>

                      <div className="peratio-container">
                      <p><span className="filter-title-selectors">PE Ratio</span> between <span className='fitler-text-span'>{peration[0]}</span> and <span className='fitler-text-span'>{peration[1]}</span></p>
                      
                    <Range railStyle={{ backgroundColor: '#9FB0BB'}} trackStyle={[{ backgroundColor: 'black' }]} handleStyle={[{ backgroundColor: 'black', borderColor: 'black',}]} min={0} max={200} defaultValue={peration === "" ? [0, 200] : peration} tipFormatter={value => `${value}`} onAfterChange={(value) => setPeration(value)}/>
                    </div>
                </div>


                <div className='edit-box-container'>

                      <div className="peratio-container">
                      <p><span className="filter-title-selectors">Price</span>  :  from <span className='fitler-text-span'>{price[0]}</span> to <span className='fitler-text-span'>{price[1]}</span> $</p>

                    <Range railStyle={{ backgroundColor: '#9FB0BB'}} trackStyle={[{ backgroundColor: 'black' }]} handleStyle={[{ backgroundColor: 'black', borderColor: 'black',}]} min={0} max={5000} defaultValue={price} tipFormatter={value => `${value} $`} onAfterChange={(value) => setPrice(value)}/>
                    </div>
                </div>

                <div className='edit-box-container'>

                    <div className="peratio-container">
                    
                    <p><span className="filter-title-selectors">Dividend Yield</span>  :  from <span className='fitler-text-span'>{dividend[0]}</span> to <span className='fitler-text-span'>{dividend[1]}</span> %</p>

                    <Range railStyle={{ backgroundColor: '#9FB0BB'}} trackStyle={[{ backgroundColor: 'black' }]} handleStyle={[{ backgroundColor: 'black', borderColor: 'black',}]} min={0} max={100} defaultValue={dividend} tipFormatter={value => `${value} %`} onAfterChange={(value) => setDividend(value)}/>
                    </div>
                </div>
                


                



               


              </div>

              
              </MediaQuery>

              <MediaQuery maxWidth={768}>

              <div className="editbox-main-container-mobile">

                <div className='edit-box-container'>

                      <div className="peratio-container">
                      <p><span className="filter-title-selectors">PE Ratio</span> between <span className='fitler-text-span'>{peration[0]}</span> and <span className='fitler-text-span'>{peration[1]}</span></p>
                    <Range railStyle={{ backgroundColor: '#9FB0BB'}} trackStyle={[{ backgroundColor: 'black' }]} handleStyle={[{ backgroundColor: 'black', borderColor: 'black',}]} min={0} max={200} defaultValue={peration === "" ? [0, 200] : peration} tipFormatter={value => `${value}`} onAfterChange={(value) => setPeration(value)}/>
                    </div>
                </div>


                <div className='edit-box-container'>

                      <div className="peratio-container">
                      <p><span className="filter-title-selectors">Price</span>  :  from <span className='fitler-text-span'>{price[0]}</span> to <span className='fitler-text-span'>{price[1]}</span> $</p>
                    <Range railStyle={{ backgroundColor: '#9FB0BB'}} trackStyle={[{ backgroundColor: 'black' }]} handleStyle={[{ backgroundColor: 'black', borderColor: 'black',}]} min={0} max={5000} defaultValue={price} tipFormatter={value => `${value} $`} onAfterChange={(value) => setPrice(value)}/>
                    </div>
                </div>

                <div className='edit-box-container'>

                    <div className="peratio-container">
                    <p><span className="filter-title-selectors">Dividend Yield</span>  :  from <span className='fitler-text-span'>{dividend[0]}</span> to <span className='fitler-text-span'>{dividend[1]}</span> %</p>

                    <Range railStyle={{ backgroundColor: '#9FB0BB'}} trackStyle={[{ backgroundColor: 'black' }]} handleStyle={[{ backgroundColor: 'black', borderColor: 'black',}]} min={0} max={100} defaultValue={dividend} tipFormatter={value => `${value} %`} onAfterChange={(value) => setDividend(value)}/>
                    </div>
                </div>

                </div>



              </MediaQuery>
              
              </div> : null}

            {loading ? (
        <div className="loading-container">
        <Loader/>
        <Loader/>
        <Loader/>
        <Loader/>
        <Loader/>
        </div>
      ) : (
            <div>
            

            
      

          <div>      
                {
                stock.map(stock => <Stockcard AddToPorofolio={AddToPorofolio} quantity={stock.quantity} name={stock.Name} image={stock.LogoURL ? "https://eodhistoricaldata.com" + stock.LogoURL :  "https://jobs.yeswehack.com/images/326c988.png" }code={stock.Code} PERatio={stock.PERatio ? stock.PERatio : 0} Sector={stock.Sector ? stock.Sector : "Undefined"} DividendShare={stock.DividendShare ? stock.DividendShare : 0} EarningsShare={stock.EarningsShare ? stock.EarningsShare : 0} DividendYield={stock.EtfYield ? stock.EtfYield/100 : 0} WallStreetTargetPrice={stock.WallStreetTargetPrice ? stock.WallStreetTargetPrice +"$" : "-"} description={stock.Description ? stock.Description : "-"} Eodstats={stock.Eodstats ? stock.Eodstats: 0} OneYearGrowth={stock.OneYearGrowth} DividendHistory={stock.DividendHistory} Currentprice={stock.Currentprice} Income_Statement={stock.Income_Statement} Cash_Flow={stock.Cash_Flow} Annual={stock.Annual} commonStockSharesOutstanding={stock.commonStockSharesOutstanding} id={stock._id}/>)}

          </div>

            <div className="page-nav">
              <p>{page > 1 ? <button className='next-previous-page' onClick={() => setPage(page + -1)}>Previous</button> : (null)} <span className="currentpage">{page}</span> <button className='next-previous-page' onClick={() => setPage(page + 1)}>Next</button></p>
            </div>
           

            </div>)}

    </div>

    </>
  );
}

export default Indices;
