import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import { CheckoutForm } from "./CheckoutForm.js";

const PUBLIC_KEY = "pk_test_51KC9lyEsUJqMnNG1UolBnMuz1qM9GWnu2tSTuEdTMUGsGld64wIW3m4dwfjKaVNjenyPm98r2DNb5XIE455OkqAT00SL0mgow0";

const stripeTestPromise = loadStripe(PUBLIC_KEY);

const options = {
    // passing the client secret obtained in step 2
    clientSecret: '{{CLIENT_SECRET}}',
    // Fully customizable with appearance API.
    appearance: {theme: 'stripe'},
};

const Stripe = () => {
  return (
  
      <CheckoutForm />
   
  );
};

export default Stripe;