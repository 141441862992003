import React from 'react'
import './Topbar.css'
import Hamburger from 'hamburger-react'
import MediaQuery from 'react-responsive';
import { useCallback, useContext, useEffect, useState } from "react"
import { UserContext } from "../context/UserContext"
import { Routes, Route, Link } from "react-router-dom";
import App from "../App"
import Auth from "../Auth"
import { useNavigate } from 'react-router'
import { baseurl } from '../env';
import logo from '../img/peakfi-dark2.png'

function Topbar() {

const [isOpen, setOpen] = useState(false)
  const [activemenu, setActivemenu] = useState(undefined)
 
  const [isloggedin, setIsloggedin] = useState(false);

  


  const navigate = useNavigate()


  
  




  const itemclicked = ()  => {
    setOpen(false)
  }


    return (
        
        <div className="topbar-container">
            <MediaQuery minWidth={768}>
            
            <div className="topbar-title">
            <Link className="logo"   to="/"  ><img className='topbar-logo' src={logo}></img></Link>
            </div>

            <div className="topbar-menu">
            
                <Link className="navmenu"   to="/stocks"  >Stocks</Link>
                <Link className="navmenu"   to="/etfs"  >ETFs</Link>
                 <Link className="navmenu"   to="/indices"  >Indices</Link>
                 <Link className="navmenu"   to="/crypto"  >Cryptos</Link>
                
                {isloggedin === true ? <p><Link className="navmenu"  to="/dashboard"  >Portofolio</Link></p> : <p><Link className="navmenu"  to="/auth"  >Portofolio</Link></p>}
                   
               

                
               <p className="topbar-menu-login-logout"> <Link className="fucking-link"  to="/auth"  >Get Started</Link></p> 
              
            </div>
            
            </MediaQuery>

            <MediaQuery maxWidth={768}>
            
            
            <div className="topbar-title">
            <Link className="logo"   to="/"  ><img className='topbar-logo' src={logo}></img></Link>
                
            </div>


            <div className="hamburger-menu">

                            <div className="hamburger">

                            
                            

                            <Hamburger toggled={isOpen} toggle={setOpen} color="black" size={20} direction="left"/>


                            {isOpen ? (
                <div className="hamburger-active">

                    <div className="closebutton-hamburger-open">
                    <Hamburger toggled={isOpen} toggle={setOpen} color="black" size={20} direction="left"/>
                    </div>



                    <div className="menu-text-container" >
                    <Link className="navmenu"   to="/stocks" onClick={()=> setOpen(false)}  >Stocks</Link>
                    </div>
                    <div className="menu-text-container">
                    <Link  className="navmenu"   to="/etfs" onClick={()=> setOpen(false)} >ETFs</Link>
                    
                    </div>
                    <div className="menu-text-container">
                    <Link onClick={()=> setOpen(false)} className="navmenu"   to="/indices" onClick={()=> setOpen(false)}  >Indices</Link>
                   
                    </div>
                    
                    <div className="menu-text-container">
                    <Link onClick={()=> setOpen(false)} className="navmenu"   to="/crypto"  >Cryptos</Link>
                    
                    </div>

                    <div className="menu-text-container">
                    {isloggedin === true ? <p><Link className="navmenu"  to="/dashboard"  onClick={()=> setOpen(false)} >Portofolio</Link></p> : <p><Link className="navmenu"  to="/auth"  >Portofolio</Link></p>}
                    </div>

                    <div className="menu-text-container">
                   <p className="topbar-menu-login-logout-mobile"> <Link onClick={()=> setOpen(false)} className="fucking-link"  to="/auth"  >Get Started</Link></p> 
              
                    
                    </div>
                    
                    

                </div>
                ):(<div></div>)}


                            

                            

                            

                            </div>

              



            </div>

            

          
            </MediaQuery>
            
        </div>
    )
}

export default Topbar
