import { useCallback, useState, useEffect, useContext } from "react"
import { useNavigate } from 'react-router'
import * as React from 'react';
import { ThemeProvider, createTheme, styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Dashboard from './Dashboard';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MenuIcon from '@mui/icons-material/Menu'
import MailIcon from '@mui/icons-material/Mail';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import './Leftmenu.css'
import { Routes, Route, Link } from "react-router-dom";
import ListItemButton from '@mui/material/ListItemButton';
import SupportIcon from '@mui/icons-material/Support';
import GridViewIcon from '@mui/icons-material/GridView';
import SettingsIcon from '@mui/icons-material/Settings';
import AddIcon from '@mui/icons-material/Add';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import Collapse from '@mui/material/Collapse';
import ListSubheader from '@mui/material/ListSubheader';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import HelpIcon from '@mui/icons-material/Help';
import DescriptionIcon from '@mui/icons-material/Description';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import { baseurl } from "./env"
import Billing from "./Billing";
import App from "./App";
import Etfs from "./Etfs";
import Crypto from "./Crypto";
import Peakfi from './img/peakfi-logo.png'
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Badge from '@mui/material/Badge';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Overview from "./Overview";
import {UserProvider, UserContext} from'./UserContext'
import { UserTabContext } from './UserTabContext'
import { UserPortfolioContext } from './UserPortfolioContext'
import { UserTriggerContext } from './UserTriggerContext';

import Community from "./Community";

import Admin from './Admin'
import Invoices from "./Invoices";
import Subscriptions from "./Subscriptions";
import Settings from './Settings'
import PeopleIcon from '@mui/icons-material/People';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import ExitToAppIcon from '@mui/icons-material/ExitToApp';


import Faq from "./Faq";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(0),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export default function PersistentDrawerLeft() {


  async function Logout () {
    localStorage.removeItem("token")
    //console.log(localStorage)
    await navigate('/auth')
}

  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

//MODAL NEW PORTFOLIO
  const [mopen, setMopen] = React.useState(false);
  const handleOpen = () => setMopen(true);
  const handleClose = () => setMopen(false);

  const [snacksuccess, setSnacksuccess] = React.useState(false);

  const handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnacksuccess(false);
  };


  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: '#2a3042',
    color: '#FFFFFF',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };


  const [creating, setCreating] = useState('Create')

  async function handleSubmit(e){
    e.preventDefault();
    setCreating('Creating...')
    
    setTrigger(!trigger)

    
        const user =  {
            portfolio: portfolioname,
            descritpion: portfoliodescription
        }
    
    // eslint-disable-next-line no-console
    const genericErrorMessage = "Something went wrong! Please try again later."

    await fetch(`${baseurl}/createportfolio`, {
        method:"POST",
        headers: {
            "content-type": "application/json",
            "x-access-token": localStorage.getItem('token')
        },
        body: JSON.stringify({portfolio: portfolioname, descritpion: portfoliodescription })
    })
    .then(res => res.json())
    .then(data => {
        function SuccessfullCreation () {
            setCreating('Successfully created !')
            setSnacksuccess(true)
            handleClose()
        }
        data.message === "Success" ? SuccessfullCreation () : setCreating('Error during creation')
        
    })
  };



const [user, setUser] = useState(undefined);
const [dashboard, setDashboard] = useState(undefined)
const [stocks, setStocks] = useState([""]);
const [loading, setLoading] = useState(undefined)
const [networth, setNetworth] = useState(0)
const [dividendyield, setDividendyield] = useState(0)
const [oneyeargrowthdash, setsetOneyeargrowthdash ] = useState(0)
const [numberofstocks, setNumberofstocks] = useState(0)
const [yearlydiv, setYearlydiv] = useState(0)
const [nbyears, setNbyears] = useState(20)
const [sortby, setSortby] = useState('quantity')
const [sectorcontainer, setSectorcontainer] = useState()
const [settings, setSettings] = useState(false)
const [quantityamount, setQuantityamount] = useState('quantity')
const [asset, setAsset] = useState('Common Stock')
const [show, setShow] = useState(false);
const [quantity, setQuantity] = useState('')
const [stocktoadd, setStocktoadd] = useState('')
const nextyeargainprevision = (networth * (oneyeargrowthdash/100))
const nextyearprevision = parseInt(networth) + nextyeargainprevision
const [fullscreen, setFullscreen] = useState(true);
const [showw, setShoww] = useState(false)
const [portfolios, setPortfolios] = useState([""])
const [portfolioname, setPortfolioname] = useState(null)
const [portfoliodescription, setPortfoliodescription]= useState(null)

const navigate = useNavigate()




  useEffect(() => {
    console.log('useffect leftmenu.js')
      fetch(`${baseurl}/isAuth`, {
          method:"GET",
          headers: {
              "x-access-token": localStorage.getItem('token')
          }
      })
      .then(res => res.json())
      .then(data => data.isLoggedIn === false ? navigate('/auth'): setTrigger(!trigger))
 
    
     
}, [])




  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };




  const lightTheme = createTheme({
    components: {
        MuiDrawer: {
          styleOverrides: {
            paper: {
              backgroundColor: "#2a3042",
              color: "#a6b0cf",
            }
          }
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                backgroundColor: "#ffffff",
                color: "#555b6d",
                fontWeight:'900'
              }
            }
          },
          MuiListSubheader: {
            styleOverrides: {
                root: {
                backgroundColor: "#2a3042",
                color: "#ffffff",
                fontWeight:'900'
              }
            }
          }
      },
    palette: {
      mode: 'light',
      primary: {
        main: '#ffffff',
      },
      thirdary: {
        main: '#4443FF',
      },
    },
  });


  const darkTheme = createTheme({
    components: {
        MuiDrawer: {
          styleOverrides: {
            paper: {
              backgroundColor: "#2a3042",
              color: "#a6b0cf",
            }
          }
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                backgroundColor: "#ffffff",
                color: "#555b6d",
                fontWeight:'900'
              }
            }
          },
          MuiListSubheader: {
            styleOverrides: {
                root: {
                backgroundColor: "#2a3042",
                color: "#ffffff",
                fontWeight:'900'
              }
            }
          }
      },
    palette: {
      mode: 'dark',
      primary: {
        main: '#ffffff',
      },
      thirdary: {
        main: '#7B68EE',
      },
    },
  });

  const [selectedIndex, setSelectedIndex] = useContext(UserTabContext);
  const [activeportfolios, setActiveportfolios] = useContext(UserPortfolioContext);
  const [value, setValue] = useContext(UserContext);
  const [trigger, setTrigger] = useContext(UserTriggerContext)
  

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };


  const [subopen, setSubopen] = React.useState(false);

  const handleClickSubmenu = () => {
    setSubopen(!subopen);
  };


function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  function SetFuckingdashboard (index) {
    
    setActiveportfolios(index)
    setSelectedIndex('dashboard')
}

const [selectvalue, setSelectvalue] =  useState(null)
function SetFuckingdashboard2 (index) {
    
  setActiveportfolios(index)
  setSelectedIndex('dashboard')
}

const [selectitem, setSelectitem] = useState('')
const handleChangeSelect = (event) => {
  setSelectitem(event.target.value);
};


/////////// STRIPE BILLING SECTION

const [url, setUrl] = useState('')


async function handleClick  (e) {

e.preventDefault()
console.log('data sent')
const response =  await fetch(`${baseurl}/create-customer-portal-session`, {
    method:"POST",
    headers: {
         "x-access-token": localStorage.getItem('token')
    }
})

const data = await response.json()
setUrl(data.url)
console.log(url)
await window.location.replace(data.url)

}


  return (
    <UserProvider>
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <ThemeProvider theme={darkTheme}>


      <ThemeProvider theme={lightTheme}>
      <AppBar position="fixed" color="primary" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
         
         
          <div className="Toolbar-name-image">
                  <div className="flex-tiem">
                    <p className="beta-version">Beta version 👋</p>
                </div>

                <div className="flex-tiem-image">
                         <NotificationsNoneIcon color="action"/>

                        <Badge badgeContent={0} color="thirdary">
                          <MailIcon color="action" />
                        </Badge>
                    
                </div>
            </div>
        </Toolbar>
      </AppBar>
      </ThemeProvider>

      <Snackbar open={snacksuccess} autoHideDuration={6000} onClose={handleCloseSnack}>
        <Alert onClose={handleCloseSnack} severity="success" sx={{ width: '100%' }}>
          Your portfolio was successfully created !
        </Alert>
      </Snackbar>
      
      <Drawer
       
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
          
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
        <div className="topbar-title-white">
            <Link className="logo-white"   to="/"  ><img className="peakfi-logo" src={Peakfi}></img></Link>
            </div>
          <IconButton onClick={handleDrawerClose}>
              
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          Overview
        </ListSubheader>
      }>

        <ListItemButton selected={selectedIndex === 0}
          onClick={(event) => handleListItemClick(event, 0)}>
        <ListItemIcon>
          <GridViewIcon />
        </ListItemIcon>
        <ListItemText primary="Overview" />
      </ListItemButton>



      <ListItemButton onClick={handleClickSubmenu} selected={selectedIndex === 2} >
        <ListItemIcon>
          <AccountBalanceWalletIcon />
        </ListItemIcon>
        
        <ListItemText primary="Portfolios" />
        <div className="badge-container">
        <Badge badgeContent={value && value.portfolios ? value.portfolios.length : null} color="thirdary">  
        </Badge>
        </div>
        {subopen ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={subopen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>

            {value && value.portfolios ? (value.portfolios.map((p, index)=> 

           
            <ListItemButton sx={{ pl: 4 }} selected={selectedIndex === 100} onClick={()=>SetFuckingdashboard(index)}>
            <ListItemIcon>
              <StarBorder />
            </ListItemIcon>
            
            <ListItemText primary={p.name} />
            <div className="badge-container">
            <Badge badgeContent={p && p.stocks && p.stocks ? p.stocks.length : 0} color="thirdary">  
            </Badge>
            </div>
          </ListItemButton>
            
            )) : null }

          

          <ListItemButton sx={{ pl: 4 }} selected={selectedIndex === 3}
           onClick={handleOpen}
            >
                
            <ListItemIcon>
            <AddIcon />
            </ListItemIcon>
            
            <ListItemText primary="New Portfolio" />
        </ListItemButton>
        <Modal
        open={mopen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
          <AddCircleOutlineIcon/> Create a new portfolio
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <div className="create-portfolio-subtitle">
            Let's create a new portfolio
            </div>
          </Typography>
          <Box
            component="form"
            sx={{
                '& > :not(style)': { m: 1, width: '25ch' },
            }}
            noValidate
            autoComplete="off"
            >
            <TextField  id="outlined-basic" label="Name" variant="outlined" placeholder="My awesome Portfolio" onChange={e => setPortfolioname(e.target.value)} />
            
            <TextField
                
                id="filled-multiline-static"
                label="Description"
                multiline
                rows={3}
                placeholder="My amazing description"
                variant="filled"
                onChange={e => setPortfoliodescription(e.target.value)} 
                />

            <Button variant="contained" color="thirdary" onClick={handleSubmit}>{creating}</Button>
            </Box>

          
          
        </Box>

        
      </Modal>

        </List>
      </Collapse>

      <List/>

      <ListItemButton selected={selectedIndex === 22}
          onClick={(event) => handleListItemClick(event, 22)}>
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="Community" />
      </ListItemButton>

        <ListItemButton selected={selectedIndex === 12}
          onClick={(event) => handleListItemClick(event, 12)}>
        <ListItemIcon>
          <GridViewIcon />
        </ListItemIcon>
        <ListItemText primary="Stocks" />
      </ListItemButton>


      <ListItemButton selected={selectedIndex === 13}
          onClick={(event) => handleListItemClick(event, 13)}>
        <ListItemIcon>
          <GridViewIcon />
        </ListItemIcon>
        <ListItemText primary="ETFs" />
      </ListItemButton>


      <ListItemButton selected={selectedIndex === 14}
          onClick={(event) => handleListItemClick(event, 14)}>
        <ListItemIcon>
          <GridViewIcon />
        </ListItemIcon>
        <ListItemText primary="Crypto" />
      </ListItemButton>

      

        </List>


      <Divider />

        <List subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          Billing
        </ListSubheader>
      }>

        <ListItemButton selected={selectedIndex === 5}
           onClick={handleClick}>
        <ListItemIcon>
          <DescriptionIcon />
        </ListItemIcon>
        <ListItemText primary="Invoices" />
      </ListItemButton>

      <ListItemButton selected={selectedIndex === 6}
          onClick={handleClick}>
        <ListItemIcon>
          <LoyaltyIcon />
        </ListItemIcon>
        <ListItemText primary="Subscriptions" />
      </ListItemButton>

      


        </List>


        <List subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          Settings
        </ListSubheader>
      }>

        <ListItemButton selected={selectedIndex === 8}
          onClick={(event) => handleListItemClick(event, 8)}>
        <ListItemIcon>
          <SettingsIcon />
        </ListItemIcon>
        <ListItemText primary="Settings" />
      </ListItemButton>

      <ListItemButton selected={selectedIndex === 9}
          onClick={(event) => handleListItemClick(event, 9)}>
        <ListItemIcon>
          <SupportIcon />
        </ListItemIcon>
        <ListItemText primary="Support" />
      </ListItemButton>

      <ListItemButton selected={selectedIndex === 10}
          onClick={(event) => handleListItemClick(event, 10)}>
        <ListItemIcon>
          <HelpIcon />
        </ListItemIcon>
        <ListItemText primary="FAQ" />
      </ListItemButton>


        </List>


        <List subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          Marketing
        </ListSubheader>
      }>

        <ListItemButton selected={selectedIndex === 11}
          onClick={(event) => handleListItemClick(event, 11)}
          >
        <ListItemIcon>
          <ConnectWithoutContactIcon />
        </ListItemIcon>
        <ListItemText primary="Affiliates" />
        <p className="soon-tag">Soon</p>
      </ListItemButton>


     


        </List>



        { value && value.data  && value.data.email === 'rondotsamuel@gmail.com' ?  (
        <List subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            Admin
          </ListSubheader>
        }>
        <ListItemButton selected={selectedIndex === 34}
          onClick={(event) => handleListItemClick(event, 34)}
          >
        <ListItemIcon>
          <ConnectWithoutContactIcon />
        </ListItemIcon>
        <ListItemText primary="Admin" />
      </ListItemButton>
      </List>
      ) : null}

       
        <button onClick={Logout} className="leftmenu-button-lgout">
        <ExitToAppIcon fontSize="small"/>Logout
        </button>
              
      </Drawer>
      </ThemeProvider>
      <Main open={open} sx={{
        width: 300,
        height: 300,
        
        
      }}>
        <DrawerHeader />
        {selectedIndex === 0 ? (<Overview/>) : selectedIndex === 'dashboard' ? (<Dashboard/>) : selectedIndex === 34 ? (<Admin/>) : selectedIndex === 22 ? (<Community/>) : selectedIndex === 12 ? (<App/>) : selectedIndex === 13 ? (<Etfs/>) : selectedIndex === 10 ? (<Faq/>) : selectedIndex === 14 ? (<Crypto/>) : selectedIndex === 5 ? (<Invoices/>) : selectedIndex === 6 ? (<Subscriptions/>) : selectedIndex === 8 ? (<Settings/>) : <Dashboard/>}
       
      </Main>
    </Box>
    </UserProvider>
  );
}