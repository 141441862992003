import React from 'react'
import './Settings.css'
import { useCallback, useState, useEffect, useContext } from "react"
import { UserContext } from './UserContext'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';

function Settings() {


    const [value, setValue] = useContext(UserContext)
    const [billing, setBilling] = useState(false)



    return (
        <div>
            <div className='billing-container'>
            <p className='portfolio-dividend-yield'>Settings</p>
                
                <div className='name-plan-container'>
                  
                    <h3> {value.data.picture ? ( <img className='settings-picture'
                    src={value.data.picture}/>) : null }{value.data.firstname}</h3>
                    <p className="portfolio-name">{value.data.plan}</p>
                </div>

                <div className='billing-adress'>
                    <h3>Billing address</h3>
                    {value.data.billing_address ? ( <p>{value.data.billing_address}</p>) : <p>No address found</p>}
                    <Button onClick={()=> setBilling(!billing)} variant="outlined">ADD billing address</Button>
                    {billing === true ? (
                        <div className='form-container'>
                            <Box
                                component="form"
                                sx={{
                                    '& .MuiTextField-root': { m: 1, width: '25ch' },
                                }}
                                noValidate
                                autoComplete="off"
                                >
                                    <TextField
                                        
                                        id="outlined-required"
                                        label="Company name"
                                        placeholder="Apple"
                                        />

                                       

                            </Box>

                            <Box
                                component="form"
                                sx={{
                                    '& .MuiTextField-root': { m: 1, width: '25ch' },
                                }}
                                noValidate
                                autoComplete="off"
                                >
                                    <TextField
                                        
                                        id="outlined-required"
                                        label="ZIP code"
                                        placeholder="95014"
                                        />

                                        <TextField
                                        
                                        id="outlined-required"
                                        label="Country"
                                        placeholder="United States of America"
                                        />

                            </Box>

                            <Box
                                component="form"
                                sx={{
                                    '& .MuiTextField-root': { m: 1, width: '25ch' },
                                }}
                                noValidate
                                autoComplete="off"
                                >
                                    <TextField
                                        
                                        id="outlined-required"
                                        label="State"
                                        placeholder="CA"
                                        />

                                        <TextField
                                        
                                        id="outlined-required"
                                        label="City"
                                        placeholder="Cupertino"
                                        />

                                       

                            </Box>

                            <Box
                                component="form"
                                sx={{
                                    '& .MuiTextField-root': { m: 1, width: '25ch' },
                                }}
                                noValidate
                                autoComplete="off"
                                >
                                   

                                        <TextField
                                        fullWidth
                                        id="outlined-required"
                                        label="Address"
                                        placeholder="One Apple Park Way Cupertino"
                                        />

                                       

                            </Box>
                            <Box
                                component="form"
                                sx={{
                                    '& .MuiTextField-root': { m: 1, width: '25ch' },
                                }}
                                noValidate
                                autoComplete="off"
                                >
                                   

                                   <Button variant="outlined">Submit</Button>
                                       

                            </Box>
                            
                            
                        </div>
                        
                    ) : null }
                </div>

                <div className='deleteaccount-div'>
                <Button variant="outlined" color="error">
                    delete account
                </Button>
                </div>

            </div>
        </div>
    )
}

export default Settings